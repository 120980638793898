import { useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import { twMerge } from "tailwind-merge";
import { emailOtpResendThunk } from "../../../../redux/slices/product";
import { dispatch, useSelector } from "../../../../redux/store";
import Input from "../../../../base-components/Input";
import Button from "../../../../base-components/Button";

const EnterEmailForm = () => {
    const { otpLoading, otpError } = useSelector((state) => state.product);

    const {
        register,
        formState: { errors },
        watch,
        trigger,
        setFocus,
    } = useFormContext();

    const [isValueChanged, setIsValueChanged] = useState(false);

    useEffect(() => {
        if (Object.keys(errors).length === 0) setIsValueChanged(false);
    }, [errors]);

    const serverError = useMemo(() => {
        if (otpError && !isValueChanged) return true;
        return false;
    }, [otpError, isValueChanged, errors]);

    const buttonRef = useRef(null);

    useEffect(() => {
        setFocus("email");
    }, [setFocus]);

    const getOtpByEmail = (e) => {
        e.preventDefault();

        const email = watch("email");
        // console.log("mobile", email);
        trigger("email", { shouldFocus: true });
        if (errors?.email || !email) {
            return;
        }
        dispatch(emailOtpResendThunk(email));
        console.log("phone number ni yu baina", email);
    };

    return (
        <>
            {/* <Typography variant="Headline" size="md">
                Бүртгүүлэх
            </Typography> */}

            <div className="mt-4">
                <Input
                    id="email"
                    placeholder="Имэйл хаяг"
                    type="email"
                    {...register("email", {
                        required: true,
                        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                        onChange: (e) => {
                            console.log(errors, "errors");
                            setIsValueChanged(true);
                        },
                    })}
                    className="w-full"
                />
                {errors?.email?.type === "required" && (
                    <p className="text-error text-xs pl-2 mt-1">
                        Имэйл хаягаа оруулна уу!
                    </p>
                )}
                {errors?.email?.type === "pattern" && (
                    <p className="text-error text-xs pl-2 mt-1">
                        Зөв форматтай имэйл хаяг оруулна уу!
                    </p>
                )}


                {serverError && ( //server error
                    <p className="text-error text-xs pl-2 mt-1">{otpError}</p>
                )}
            </div>
            <div
                className={twMerge(["justify-center mt-2 hidden", otpLoading && "flex"])}
            >
                <PulseLoader
                    color="#BBC1C9"
                    speedMultiplier={0.7}
                    loading={otpLoading}
                    size={12}
                />
            </div>
            <div className="mt-6 flex justify-end items-center">
                <Button ref={buttonRef} type="button" onClick={(e) => getOtpByEmail(e)}>
                    Үргэлжлүүлэх
                </Button>
            </div>
        </>
    );
};

export default EnterEmailForm;
