import { useEffect } from "react";
import { dispatch } from "../../redux/store";
import { getUserInfo } from "../../redux/slices/profile";
import { Outlet } from "react-router-dom";

const Dashboard = () => {
  useEffect(() => {
    dispatch(getUserInfo());
  }, []);
  return (
    <div className="flex flex-col h-[calc(100%-72px)]">
      <div className="flex-grow pb-24 md:pb-0">
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
