import { useState, useEffect } from "react";

function useElementSize(element) {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    // Ensure the ref element is available
    if (!element) return;

    // Function to update the size
    const updateSize = () => {
      const { offsetWidth: width, offsetHeight: height } = element;
      setSize({ width, height });
    };

    // Create a ResizeObserver to observe the size of the element
    const resizeObserver = new ResizeObserver(updateSize);

    // Start observing the element
    resizeObserver.observe(element);

    // Initial size update
    updateSize();

    // Clean up: Disconnect the observer when the component unmounts
    return () => {
      resizeObserver.disconnect();
    };
  }, [element]); // Re-run the effect when ref changes

  return size;
}

export default useElementSize;
