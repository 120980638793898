import { useEffect, useMemo, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import { twMerge } from "tailwind-merge";

import Button from "../../../base-components/Button";
import Typography from "../../../base-components/Typography";
import Input from "../../../base-components/Input";
import { dispatch, useSelector } from "../../../redux/store";
import { checkRegisterThunk } from "../../../redux/slices/register";

const Mobile = () => {
  const { loading, error } = useSelector((state) => state.register);

  const {
    register,
    formState: { errors },
    watch,
    trigger,
    setFocus,
  } = useFormContext();

  const [isValueChanged, setIsValueChanged] = useState(false);

  useEffect(() => {
    if (Object.keys(errors).length === 0) setIsValueChanged(false);
  }, [errors]);

  const serverError = useMemo(() => {
    if (error && !isValueChanged) return true;
    return false;
  }, [error, isValueChanged, errors]);

  // const buttonRef = useRef(null);

  useEffect(() => {
    setFocus("phoneNumber");
  }, [setFocus]);

  const getOtpBySms = (e) => {
    e.preventDefault();

    const phoneNumber = watch("phoneNumber");
    // console.log("mobile", phoneNumber);
    trigger("phoneNumber", { shouldFocus: true });
    if (errors?.phoneNumber || !phoneNumber) {
      return;
    }
    dispatch(checkRegisterThunk(phoneNumber));
    console.log("phone number ni yu baina", phoneNumber);
  };

  return (
    <>
      <Typography variant="Headline" size="md">
        Бүртгүүлэх
      </Typography>
      <div className="mt-4">
        <Input
          id="phoneNumber"
          placeholder="Утасны дугаар"
          type="number"
          {...register("phoneNumber", {
            required: true,
            maxLength: 8,
            minLength: 8,
            pattern: /^[7-9]{1}?[0-9]{7}$/,
            onChange: (e) => {
              console.log(errors, "errors");
              setIsValueChanged(true);
            },
          })}
          // onKeyDown={(e) => {
          //   if (e.code === "Enter") {
          //     e.preventDefault();
          //     if (validateMobileNumber) {
          //       if (buttonRef.current) {
          //         buttonRef.current.focus();
          //       }
          //     } else {
          //       trigger("phoneNumber", { shouldFocus: true });
          //     }
          //   }
          // }}
          className="w-full"
        />
        {errors?.phoneNumber?.type === "required" && (
          <p className="text-error text-xs pl-2 mt-1">
            Утасны дугаараа оруулна уу!
          </p>
        )}
        {(errors?.phoneNumber?.type === "maxLength" ||
          errors?.phoneNumber?.type === "minLength") && (
            <p className="text-error text-xs pl-2 mt-1">
              Утасны дугаар 8 оронтой байх шаардлагатай!
            </p>
          )}
        {errors?.phoneNumber?.type === "pattern" && (
          <p className="text-error text-xs pl-2 mt-1">
            Утасны дугаар буруу байна. Шалгана уу!
          </p>
        )}
        {errors?.phoneNumber?.type === "custom" && (
          <p className="text-error text-xs pl-2 mt-1">
            {errors?.phoneNumber?.message}
          </p>
        )}

        {serverError && ( //server error
          <p className="text-error text-xs pl-2 mt-1">{error}</p>
        )}
      </div>
      <div
        className={twMerge(["justify-center mt-2 hidden", loading && "flex"])}
      >
        <PulseLoader
          color="#BBC1C9"
          speedMultiplier={0.7}
          loading={loading}
          size={12}
        />
      </div>
      <div className="mt-6 flex justify-end items-center">
        <Button  type="button" onClick={(e) => getOtpBySms(e)}>
          Үргэлжлүүлэх
        </Button>
      </div>
    </>
  );
};

export default Mobile;
