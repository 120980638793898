import { useEffect, useState } from "react";
import Button from "../../../base-components/Button";
import Typography from "../../../base-components/Typography";
import { LuCopy } from "react-icons/lu";
import { dispatch, useSelector } from "../../../redux/store";
import { toast } from "react-toastify";
import { checkInvoiceThunk, closeMoneyModal } from "../../../redux/slices/savings";

const Account = () => {
  const [isCopied, setIsCopied] = useState(false);
  const { paymentResponse } = useSelector(state => state.savings)
  useEffect(() => {
    setTimeout(() => {
      if (isCopied) {
        setIsCopied(false);
      }
    }, 100 * 10);
  }, [isCopied]);

  const copyText = (text) => {
    // let value = document.getElementById(method).childNodes[0].textContent;
    navigator.clipboard.writeText(text);
    setIsCopied(true);
  };
  useEffect(() => {
    if (isCopied) {
      toast.success("Амжилттай хууллаа!");
      setIsCopied(false)
    }
  }, [isCopied])

  return (
    <>
      <div className="grid gap-2 border border-primary rounded-base px-6 py-3 mt-4">
        <div className="grid gap-1">
          <Typography className="text-lg font-medium">
            Шилжүүлэх банк:
          </Typography>
          <Typography className="text-[17px]">{paymentResponse.bankName}</Typography>
        </div>
        <div className="grid gap-1">
          <Typography className="text-lg font-medium">
            Шилжүүлэх данс:
          </Typography>
          <div className="flex items-center space-x-4">
            <Typography id="account" className="text-[17px]">
              {paymentResponse.accountNumber}
            </Typography>
            <button
              className="outline-none flex items-center space-x-2"
              onClick={() => copyText(paymentResponse.accountNumber)}
            >
              <LuCopy className="text-primary" />
              {/* <Typography
                className={twMerge([
                  "text-success hidden transition-all",
                  isCopied && "block",
                ])}
              >
                Copied
              </Typography> */}
            </button>
          </div>
        </div>
        <div className="grid gap-1">
          <Typography className="text-lg font-medium">
            Гүйлгээний утга:
          </Typography>
          <div className="flex items-center space-x-4">
            <Typography id="value" className="text-[17px]">
              {paymentResponse.description}
            </Typography>
            <button className="outline-none" onClick={() => copyText(paymentResponse.description)}>
              <LuCopy className="text-primary" />
            </button>
          </div>
        </div>
        <div className="grid gap-1">

          <Typography className="text-lg font-medium">Мөнгөн дүн:</Typography>
          <div className="flex items-center space-x-4">
            <Typography id="value" className="text-[17px]">
              {paymentResponse.amount} ₮
            </Typography>
            <button className="outline-none" onClick={() => copyText(paymentResponse.amount)}>
              <LuCopy className="text-primary" />
            </button>
          </div>
          {/* <Typography className="text-[17px]">{paymentResponse.amount} ₮</Typography>
          <button className="outline-none" onClick={() => copyText(paymentResponse.amount)}>
            <LuCopy className="text-primary" />
          </button> */}
        </div>
      </div>
      <div className="flex items-center justify-center">
        <Button className="mt-4" onClick={() => {
          dispatch(closeMoneyModal());
        }}>
          Хаах
        </Button>
      </div>
    </>
  );
};
export default Account;
