import { useEffect } from "react";
import Button from "../../../base-components/Button";
import { checkInvoiceThunk } from "../../../redux/slices/savings";
import { dispatch, useSelector } from "../../../redux/store";

const Base64ImageDisplay = () => {
  const { paymentResponse, invoiceError } = useSelector((state) => state.savings);

  console.log("end yu baina", paymentResponse);
  // useEffect(() => {
  //   if (paymentResponse?.invoice_id) {
  //     const intervalId = setInterval(() => {
  //       dispatch(checkInvoiceThunk(paymentResponse.invoice_id));
  //     }, 1000); // 1000ms = 1 second

  //     // Cleanup interval when the component unmounts
  //     return () => clearInterval(intervalId);
  //   }
  // }, [paymentResponse?.invoice_id]); // Depend on `paymentResponse.invoice_id`
  return (
    <div className="flex flex-col items-center justify-center bg-white">
      {/* <QRCode value={paymentResponse.qr_text} /> */}
      <img
        id="invoiceQR"
        src={`data:image/png;base64,${paymentResponse?.qr_image}`}
      />
      {
        invoiceError && <p className="text-error text-xs pl-2 mt-1">{invoiceError}</p>
      }
      <Button
        className="mt-4"
        onClick={() => {
          dispatch(checkInvoiceThunk(paymentResponse.invoice_id));
        }}
      >
        Шалгах
      </Button>
    </div >
  );
};
export default Base64ImageDisplay;
