import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { LuMoon, LuSun, LuUserRound } from "react-icons/lu";
import { ReactComponent as User } from "../../assets/icon/user.svg";
import { ReactComponent as LogOut } from "../../assets/icon/log-out.svg";
import { ReactComponent as ChevronRight } from "../../assets/icon/chevron-right-1.svg";
import { Icon } from "../utils/menuIcons";
import IconButton from "../../base-components/IconButton";
import Typography from "../../base-components/Typography";
import CustomizedTooltip from "../../base-components/Tooltip";
import Dropdown from "../../components/Dropdown";
import LanguageSelector from "../../components/LanguageSelector";
import { useStore } from "../../stores";
import { useTheme } from "../../contexts/ThemeContext";
import { dispatch, useSelector } from "../../redux/store";
import { logout } from "../../redux/slices/auth";
import NotificationPopover from "../../pages/notification";
import { useIsLg } from "../../hooks/mediaQuery";
import { useWindowSize } from "../../hooks/windowSize";

function Layout() {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const { theme, changeTheme } = useTheme();
  const { t } = useTranslation();
  const menus = t("menus", { returnObjects: true });

  const selectedMenu = useStore.useSelectedMenu();
  const setSelectedMenu = useStore.useSetSelectedMenu();
  const { logged, username } = useSelector((state) => state.auth);

  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    setSelectedMenu(window.location.pathname);
  }, []);

  const selectMenu = (menu) => {
    setSelectedMenu(menu.path);
    if (menu.path === "/invite-friend") navigate("coming-soon");
    else navigate(menu.path);
  };

  useEffect(() => {
    // if (!logged) {
    //   navigate("/login");
    // }
    if (window.location.pathname === "/") navigate("home");
    setSelectedMenu(window.location.pathname);
  }, [navigate]);

  if (logged)
    return (
      <div className="bg-page h-screen">
        <div className="flex">
          {/* Sidebar */}
          <div
            id="sidebar"
            className="fixed inset-y-0 left-0 lg:w-sidebar w-auto bg-white shadow-base border border-[#ECEEF6] rounded-base max-xs:hidden"
          >
            <div className="h-full py-7 lg:px-8 md:px-4 px-2 relative">
              <div className="flex items-center justify-center">
                <div className="max-w-40 lg:w-40 w-16">
                  {width > 1024 ? (
                    <img
                      src="/gerege_logo.png"
                      alt="logo"
                      className="object-fit"
                    />
                  ) : (
                    <img
                      src="/mobile_logo.png"
                      alt="logo"
                      className="object-fit"
                    />
                  )}
                  {/* <Typography variant={isSm ? "Headline" : "Title"}>
                  {t("title")}
                </Typography> */}
                </div>
              </div>
              <div className="mt-16 max-lg:flex flex-col items-center">
                {menus.map((menu, index) => (
                  <div
                    key={index}
                    className={twMerge([
                      "group flex items-center py-2.5 px-4 space-x-4 rounded-lg cursor-pointer w-52 hover:bg-surface-gray/[0.12]", // transition-all
                      "max-lg:flex-col max-lg:w-24 max-lg:space-y-2 max-lg:space-x-0",
                    ])}
                    onClick={() => selectMenu(menu)}
                  >
                    <div
                      className={twMerge([
                        "p-2 rounded-[10px]", //
                        selectedMenu.includes(menu.path) &&
                          "bg-primary shadow-base",
                      ])}
                    >
                      {Icon({
                        id: menu.id,
                        isSelected: selectedMenu.includes(menu.path),
                      })}
                    </div>
                    <span className="text-primary max-lg:text-center group-hover:text-primary/85">
                      {menu.title}
                    </span>
                  </div>
                ))}
              </div>
              <div className="absolute bottom-16">
                <div
                  className={twMerge([
                    "flex items-center mt-4 py-2.5 px-4 space-x-4 rounded-lg cursor-pointer transition-all w-52 hover:bg-surface-gray/[0.12]",
                    "max-lg:flex-col max-lg:w-24 max-lg:space-y-2 max-lg:space-x-0",
                  ])}
                  onClick={() => dispatch(logout(navigate))}
                >
                  <LogOut
                    stroke="#025867"
                    strokeWidth={1}
                    className="w-5 h-5"
                  />
                  <span className={"ml-4 transition-color"}>{t("logout")}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="grow flex-1 min-h-screen h-full ml-0 lg:ml-[274px] md:ml-[130px] xs:ml-[114px]">
            <div className="flex justify-end p-4 max-xs:px-4 rounded-base border border-[#ECEEF6] bg-white z-10 sticky top-0">
              {/* <div className="relative flex items-center">
                <SearchIcon
                  stroke="#D9E0E8"
                  className="absolute ml-2 w-5 h-5"
                />
                <input
                  className="rounded-full border border-stroke px-4 py-2 pl-8 bg-surface-gray outline-none text-primary text-sm"
                  placeholder="Хайх"
                />
              </div> */}
              <div className="flex items-center space-x-3">
                {/* <LanguageSelector />
                <button
                  className="flex items-center space-x-2"
                  onClick={() =>
                    changeTheme(theme === "dark" ? "light" : "dark")
                  }
                >
                  <IconButton variant="outlinePrimary" size="sm">
                    <LuMoon className="block dark:hidden h-5 w-5" />
                    <LuSun className="hidden dark:block h-5 w-5" />
                  </IconButton>
                </button> */}
                <NotificationPopover />

                <button
                  className="flex items-center space-x-2"
                  onClick={() => setIsShowing(!isShowing)}
                >
                  <IconButton variant="outlinePrimary" size="sm">
                    <LuUserRound size={25} />
                  </IconButton>
                  {/* <span className="max-[500px]:hidden">{username}</span> */}
                </button>
                <Dropdown show={isShowing} className="mt-6">
                  <Dropdown.Header>
                    <div className="flex items-center space-x-2">
                      <IconButton variant="outlinePrimary" size="sm">
                        <User stroke="primary" className="h-5 w-5" />
                      </IconButton>
                      <span>{username}</span>
                    </div>
                    <ChevronRight />
                  </Dropdown.Header>
                  <Dropdown.Panel>
                    <div
                      className="flex items-center px-4 py-3.5 hover:cursor-pointer"
                      onClick={() => dispatch(logout(navigate))}
                    >
                      <LogOut stroke="#B7281E" className="w-4 h-4" />
                      <Typography size="md" className="ml-4 text-error">
                        Гарах
                      </Typography>
                    </div>
                  </Dropdown.Panel>
                </Dropdown>
              </div>
            </div>
            <Outlet />
          </div>
        </div>

        {/* Mobile Footer */}
        <div className="xs:hidden flex justify-evenly bg-primary fixed bottom-0 right-0 left-0">
          {menus.map((menu, index) => (
            <CustomizedTooltip
              key={index}
              title={menu.title}
              placement="bottom-end"
            >
              <div
                className={twMerge([
                  "flex flex-col items-center space-y-2 max-[353px]:my-2 p-4 cursor-pointer transition-all rounded-lg truncate hover:bg-white/[0.04]",
                  "w-min",
                  selectedMenu.includes(menu.path) && "bg-white/[0.08]",
                ])}
                onClick={() => selectMenu(menu)}
              >
                {Icon({
                  id: menu.id,
                  isSelected: true, // selectedMenu.includes(menu.path),
                  // mobile: true,
                })}
                <Typography
                  variant="Label"
                  size="md"
                  className={twMerge([
                    "transition-color hidden xs:block",
                    selectedMenu.includes(menu.path)
                      ? "text-secondary"
                      : "text-gray",
                  ])}
                >
                  {menu.title}
                </Typography>
              </div>
            </CustomizedTooltip>
          ))}
        </div>
      </div>
    );
  console.log("logged yu baina ", logged);
  if (window.location.pathname == "/dan-response" && logged != undefined)
    return (
      <div className="bg-page flex flex-col h-screen">
        <div className="flex flex-grow">
          {/* Sidebar */}

          <div className="w-full">
            <Outlet />
          </div>
        </div>
      </div>
    );
}

export default Layout;
