import { useEffect, useState } from "react";
import Modal from "../../../base-components/Modal";
import SavingsForm from "./modals/Form";
import SavingsInfo from "./modals/Info";
import Schedule from "./modals/Schedule";
import Contract from "./modals/Contract";
import RegisterForm from "./modals/RegisterInput";
import EnterEmail from "./modals/EnterEmail";
import EmailOtpVerify from "./modals/EmailVerify";
import { dispatch, useSelector } from "../../../redux/store";
import { setStep } from "../../../redux/slices/product";

const CreateSavings = ({ isOpen, onClose }) => {
  const { step } = useSelector((state) => state.product);
  useEffect(() => {
    if (step > 7) {
      onClose();
    }
  }, [step]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Хуримтлал үүсгэх"}
      withCloseButton={step === 1}
      withBackButton={step > 1}
      onBackClick={() => {
        dispatch(setStep(1));
      }}
      size={step === 7 ? "5xl" : "lg"}
    >
      {step === 1 && (
        <SavingsInfo
          {...{
            step,
            setStep: (step) => {
              dispatch(setStep(step));
            },
          }}
        />
      )}
      {step === 2 && (
        <EnterEmail
          {...{
            step,
            setStep: (step) => {
              dispatch(setStep(step));
            },
          }}
        />
      )}
      {step === 3 && (
        <EmailOtpVerify
          {...{
            step,
            setStep: (step) => {
              dispatch(setStep(step));
            },
          }}
        />
      )}
      {step === 4 && (
        <RegisterForm
          {...{
            step,
            setStep: (step) => {
              dispatch(setStep(step));
            },
          }}
        />
      )}
      {step === 5 && (
        <SavingsForm
          {...{
            step,
            setStep: (step) => {
              dispatch(setStep(step));
            },
          }}
        />
      )}
      {step === 6 && (
        <Schedule
          {...{
            step,
            setStep: (step) => {
              dispatch(setStep(step));
            },
          }}
        />
      )}
      {step === 7 && (
        <Contract
          {...{
            step,
            setStep: (step) => {
              dispatch(setStep(step));
            },
          }}
        />
      )}
    </Modal>
  );
};

export default CreateSavings;
