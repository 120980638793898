import Typography from "../../base-components/Typography";
import { useSelector } from "../../redux/store";

const UserInfo = () => {
  const { user } = useSelector((state) => state.profile);
  console.log("user ni yu baina", user);
  return (
    <form className="space-y-4">
      <div className="flex items-center space-x-2">
        <Typography variant="Label" className="text-font-secondary">
          Овог:
        </Typography>
        <Typography>{user?.lastName}</Typography>
      </div>
      <div className="flex items-center space-x-2">
        <Typography variant="Label" className="text-font-secondary">
          Нэр:
        </Typography>
        <Typography>{user?.firstName}</Typography>
      </div>
      <div className="flex items-center space-x-2">
        <Typography variant="Label" className="text-font-secondary">
          Регистерийн дугаар:
        </Typography>
        <Typography>{user?.regNum}</Typography>
      </div>
      <div className="flex items-center space-x-2">
        <Typography variant="Label" className="text-font-secondary">
          Оршин суугаа хаяг:
        </Typography>
        <Typography>{user?.passportAddress}</Typography>
      </div>
    </form>
  );
};

export default UserInfo;
