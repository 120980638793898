import Service from "./BaseService";

const getProductList = (config) =>
  Service.get(`/api/v1/product/list`, { ...config, nonLoading: true }, 8080);
const getProductDetail = (productId, config) =>
  Service.get(
    `/api/v1/product/detail?productId=${productId}`,
    { ...config, nonLoading: true },
    8080
  );

const getSchedule = (data, config) =>
  Service.post(
    `/api/v1/order/schedule`,
    data,
    { ...config, nonLoading: true },
    8080
  );



const emailOtpVerify = ({ email, otpValue }, config) => Service.post(`/api/v1/confirm/email?email=${email}&optValue=${otpValue}`, {}, { ...config, nonLoading: true }, 8080);

const emailOtpResend = (email, config,) => Service.post(`/api/v1/send/email?email=${email}`, {}, { ...config, nonLoading: true }, 8080);

const createOrder = (data, config) =>
  Service.post(
    `/api/v1/order/execute`,
    data,
    { ...config, nonLoading: true },
    8080
  );
const ProductService = {
  getProductList,
  getProductDetail,
  getSchedule,
  emailOtpVerify,
  emailOtpResend,
  createOrder
};

export default ProductService;
