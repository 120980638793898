import { useEffect, useState } from "react";
import { TbChevronLeft } from "react-icons/tb";
import { useSelector } from "react-redux";
import { dispatch } from "../../redux/store";
import {
  changeInvoiceSuccess,
  getOrderDetailThunk,
  getOrderListThunk,
  getOrderScheduledThunk,
  setSelectOrderId,
} from "../../redux/slices/savings";
import Typography from "../../base-components/Typography";
import Button from "../../base-components/Button";
import Statement from "./Statement";
import { useNavigate } from "react-router-dom";
import CustomMonthPicker from "../../base-components/MonthPicker";
import Loader from "../../components/Loader";
import Modal from "../../base-components/Modal";
import { toast } from "react-toastify";

const Savings = () => {

  const {
    loading,
    orders,
    selectedOrder,
    selectOrderId,
    moneyAddModal,
    invoiceSuccess,
    startDate,
    endDate,

  } = useSelector((state) => state.savings);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(getOrderListThunk());
  }, []);

  //   productName - Бүтээгдэхүүний нэр
  // paidMonth - Төлсөн сар
  // duration - Нийт төлөх сар
  // total - нийт цуглуулахаар зорьж буй дүн
  // lastBalance - Цуглуулсан дүн
  // calculatedInterest - Бодогдсон хүү
  // interestOfMonth - Сарын хүү
  // dayOfMonth - Сар болгон төлөх өдөр
  // endDate- сүүлийн төлөлтийн огноо

  useEffect(() => {
    if (invoiceSuccess) {
      // alert("Amjilttai tseneglelee");

      toast.success("Амжилттай цэнэглэлээ");
      //ene deer huurhun alert haruulah
      dispatch(getOrderListThunk());
      dispatch(changeInvoiceSuccess());
      dispatch(
        getOrderDetailThunk(
          selectOrderId,
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD")
        )
      );
    }
  }, [invoiceSuccess]);

  console.log(selectedOrder, "selectedOrder");

  const calculateProgress = (item) => {
    const progress = (item.paidMonth * 100) / item.duration + "%";
    console.log("calculateProgress", progress);
    return progress;
  };

  const onCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div className="p-6">
        <Loader isLoading={loading} />
        <Button
          variant="text"
          icon={<TbChevronLeft size={26} />}
          onClick={() => navigate("/home")}
          className="mb-4"
        >
          Буцах
        </Button>
        {(!orders || (orders && orders.length == 0)) && <Typography
          variant="Headline"
          size="sm"
          className="font-normal mb-6"
        >
          Хуримтлал үүсээгүй байна
        </Typography>}
        <div className="flex flex-col lg:flex-row gap-8 mt-6">

          <div className="flex-1 lg:basis-1/3">
            {orders && orders.length > 0 && (
              <div>
                <Typography
                  variant="Headline"
                  size="sm"
                  className="font-normal mb-6"
                >
                  Миний хуримтлал
                </Typography>
                {/* Order list with scroll */}
                <ul
                  className="grid sm:grid-cols-1 md:grid-cols-1 gap-8 overflow-y-auto max-h-[800px] pr-2"
                >
                  {orders.map((item, index) => (
                    <li
                      key={index}
                      aria-selected={selectOrderId === item?.id}
                      className={`grid gap-2 p-5 bg-white rounded-lg shadow-md 
                    hover:bg-gray-100 cursor-pointer 
                    ${selectOrderId === item?.id
                          ? "border border-blue-500"
                          : ""
                        }`}
                      onClick={() => {
                        dispatch(setSelectOrderId(item.id));
                      }}
                    >
                      <div className="flex items-center justify-between">
                        <Typography className="text-xl font-semibold">
                          {item.productName}
                        </Typography>
                        <CustomMonthPicker />
                        <Typography className="font-normal">
                          сар бүрийн {item.dayOfMonth}
                        </Typography>
                      </div>

                      <Typography className="font-normal">
                        {(item.lastBalance ?? 0) + (item.calculatedInterest ?? 0)}₮
                      </Typography>
                      <div>
                        <div className="flex items-center justify-between">
                          <Typography className="text-sm">Цуглуулсан дүн :</Typography>
                          <Typography className="font-normal">
                            {item.lastBalance ?? 0}₮
                          </Typography>
                        </div>
                        <div className="flex items-center justify-between">
                          <Typography className="text-sm">
                            Хуримтлагдсан хүү :
                          </Typography>
                          <Typography className="font-normal">
                            {item.calculatedInterest ?? 0}₮
                          </Typography>
                        </div>
                        <div className="flex items-center justify-between">
                          <Typography className="text-sm">Хүү сар :</Typography>
                          <Typography className="font-normal">
                            {item.interestOfMonth ?? 0}%
                          </Typography>
                        </div>

                        <div className="flex items-center justify-between">
                          <Typography className="text-sm">
                            Хуримтлал дуусах хугацаа :
                          </Typography>
                          <Typography className="font-normal">
                            {item.endDate ?? 0}
                          </Typography>
                        </div>
                      </div>

                      <div className="h-6 bg-gray-200 rounded-xl">
                        <div
                          style={{ width: calculateProgress(item) }}
                          className="h-full w-full bg-primary rounded-base"
                        ></div>
                      </div>
                      <div className="flex items-center justify-between">
                        <Typography size="md" className="font-normal">
                          {item.paidMonth ?? 0} сар төлсөн
                        </Typography>

                        <Typography size="md" className="font-normal">
                          {item.duration} сар
                        </Typography>
                      </div>
                      <button
                        className="mt-4 w-full py-1 bg-primary text-white font-normal rounded-full shadow-md hover:bg-primary/90 focus:outline-none"
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(getOrderScheduledThunk(item.id));
                        }}
                      >
                        Мөнгө нэмэх
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="flex-1 lg:basis-2/3">
            {orders && orders.length > 0 && <Statement className="w-full lg:w-1/2" />}
          </div>
        </div>
      </div>

    </>
  );
};

export default Savings;
