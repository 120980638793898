import { TbHome } from "react-icons/tb";
import {
  MdAccountBalance,
  MdOutlineAccountBalance,
  MdOutlineSavings,
} from "react-icons/md";
import { LuUserRound } from "react-icons/lu";

export function Icon({ id, isSelected, mobile }) {
  switch (id) {
    case "home":
      if (isSelected && !mobile) return <TbHome size={20} color="#fff" />;
      else return <TbHome size={24} />;
    case "user":
      if (isSelected && !mobile) return <LuUserRound color="#fff" size={20} />;
      else return <LuUserRound size={24} />;
    case "account-management":
      if (isSelected && !mobile)
        return <MdAccountBalance color="#fff" size={20} />;
      else return <MdOutlineAccountBalance size={24} />;
    case "savings":
      if (isSelected && !mobile)
        return <MdOutlineSavings color="#fff" size={20} />;
      else return <MdOutlineSavings size={24} />;
    default:
      break;
  }
}
