import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import Typography from "../../../base-components/Typography";
import { twMerge } from "tailwind-merge";
import Input from "../../../base-components/Input";

const validateMinLength = (password) => {
  return password.length >= 8;
};

const hasUpperCase = (password) => {
  return /[A-Z]/.test(password);
};

const hasLowerCase = (password) => {
  return /[a-z]/.test(password);
};

const hasNumber = (password) => {
  return /[0-9]/.test(password);
};

const hasSpecialChars = (password) => {
  return /[!@#$%^&*(),.?":{}|<>]/.test(password);
};

const Password = ({ loading }) => {
  const {
    register,
    formState: { errors },
    watch,
    setError,
    setFocus,
  } = useFormContext();

  useEffect(() => {
    setFocus("password");
  }, [setFocus]);

  return (
    <>
      <div>
        <Typography variant="Headline" size="md" className="mb-4">
          Нууц үг
        </Typography>
      </div>
      <div className="space-y-4">
        <div>
          <Input
            id="sign-in-pass"
            label="Шинэ нууц үг"
            type="password"
            {...register("password", {
              required: true,
              maxLength: 30,
              minLength: 8,
              validate: {
                hasUpperCase: (v) => hasUpperCase(v),
                hasLowerCase: (v) => hasLowerCase(v),
                hasNumber: (v) => hasNumber(v),
                hasSpecialChars: (v) => hasSpecialChars(v),
              },
            })}
            className="w-full"
          />
          <p
            className={twMerge([
              "text-error text-xs pl-2 mt-1",
              validateMinLength(watch("password")) && "text-success",
            ])}
          >
            *Хамгийн багадаа 8 тэмдэгт
          </p>
          <p
            className={twMerge([
              "text-error text-xs pl-2 mt-1",
              hasUpperCase(watch("password")) && "text-success",
            ])}
          >
            *Том үсэг агуулсан байх
          </p>
          <p
            className={twMerge([
              "text-error text-xs pl-2 mt-1",
              hasLowerCase(watch("password")) && "text-success",
            ])}
          >
            *Жижиг үсэг агуулсан байх
          </p>
          <p
            className={twMerge([
              "text-error text-xs pl-2 mt-1",
              hasNumber(watch("password")) && "text-success",
            ])}
          >
            *Тоо агуулсан байх
          </p>
          <p
            className={twMerge([
              "text-error text-xs pl-2 mt-1",
              hasSpecialChars(watch("password")) && "text-success",
            ])}
          >
            *Тусгай тэмдэгт агуулсан байх
          </p>
        </div>
        <div>
          <Input
            id="sign-in-pass-repeat"
            label="Нууц үг давтах"
            type="password"
            {...register("re_password", {
              required: true,
              maxLength: 30,
            })}
            className="w-full"
          />
          {errors?.re_password?.type === "custom" && (
            <p className="text-error text-xs pl-2 mt-1">
              {errors?.re_password?.message}
            </p>
          )}
        </div>
        <div className={twMerge(["justify-center hidden", loading && "flex"])}>
          <PulseLoader
            color="#BBC1C9"
            speedMultiplier={0.7}
            loading={loading}
            size={12}
          />
        </div>
      </div>
    </>
  );
};

export default Password;
