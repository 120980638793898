import { TbChevronLeft } from "react-icons/tb";
import Button from "../../../../base-components/Button";
import Typography from "../../../../base-components/Typography";
import { useNavigate } from "react-router-dom";
import Stepper from "../../../../base-components/Stepper";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import FirstStep from "./steps/FirstStep";
import { dispatch, useSelector } from "../../../../redux/store";
import { getProductDetail } from "../../../../redux/slices/product";
import { twMerge } from "tailwind-merge";
import { useWindowSize } from "../../../../hooks/windowSize";
import { setStep } from "../../../../redux/slices/register";
import SecondStep from "./steps/SecondStep";
import ThirdStep from "./steps/ThirdStep";

const steps = [1, 2, 3];

const CreateSavings = () => {
  const navigate = useNavigate();
  const { products, step, loading, productLoading } = useSelector(
    (state) => state.product
  );

  const methods = useForm({
    defaultValues: {},
  });

  const { height } = useWindowSize();

  const onSubmit = (data) => {
    console.log(data, "data");
  };

  return (
    <div className="p-4">
      <Button
        variant="text"
        icon={<TbChevronLeft size={26} />}
        onClick={() => {
          navigate("/home");
          dispatch(setStep(0));
        }}
      >
        Буцах
      </Button>
      <Typography variant="Headline" size="sm" className="font-medium m-4">
        Санал болгож буй бүтээгдэхүүн
      </Typography>
      <div className="flex px-4 gap-4">
        <div
          style={{ height: height - 198 }}
          className="grid gap-4 overflow-auto"
        >
          {products.map((product, index) => (
            <button
              key={product.id}
              className="outline-none rounded-base bg-card-base p-3.5 mx-2 grid gap-2 mr-4 first:border first:border-primary"
              onClick={() => {
                // dispatch(getProductDetail(product.id));
                // navigate("product-detail");
              }}
            >
              <div
                className={twMerge([
                  "h-40 w-60 rounded-base",
                  !product.imagePath && "bg-[#D9D9D9]",
                ])}
              >
                {product.imagePath && (
                  <img
                    src={
                      "https://geregefund.mn" +
                      product.imagePath.replace(
                        "https://admin.geregefund.mn",
                        ""
                      )
                    }
                    alt={product.name}
                    className="w-full h-full object-cover rounded-base"
                  />
                )}
              </div>
              <p className="text-center font-semibold second-line-ellipsis">
                {product.name}
              </p>
            </button>
          ))}
        </div>
        <div className="shadow-card rounded-base p-6 grow">
          <Stepper steps={steps} activeStep={step} />
          <FormProvider {...methods}>
            <form
              onSubmit={methods.handleSubmit(onSubmit)}
              className="p-4 pb-0"
            >
              {step === 0 && (
                <FirstStep
                  {...{
                    step,
                    setStep: (step) => {
                      dispatch(setStep(step));
                    },
                  }}
                />
              )}
              {step === 1 && (
                <SecondStep
                  {...{
                    step,
                    setStep: (step) => {
                      dispatch(setStep(step));
                    },
                  }}
                />
              )}
              {step === 2 && (
                <ThirdStep
                  {...{
                    step,
                    setStep: (step) => {
                      dispatch(setStep(step));
                    },
                  }}
                />
              )}
            </form>
          </FormProvider>
        </div>
      </div>
    </div>
  );
};

export default CreateSavings;
