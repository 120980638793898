import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  changedPasswordSuccess,
  changePasswordThunk,
} from "../../redux/slices/profile";
import { PulseLoader } from "react-spinners";
import { twMerge } from "tailwind-merge";
import Input from "../../base-components/Input";

const validateMinLength = (password) => password.length >= 8;
const hasUpperCase = (password) => /[A-Z]/.test(password);
const hasLowerCase = (password) => /[a-z]/.test(password);
const hasNumber = (password) => /[0-9]/.test(password);
const hasSpecialChars = (password) => /[!@#$%^&*(),.?":{}|<>]/.test(password);

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { changePasswordLoading, changePasswordSuccess } = useSelector(
    (state) => state.profile
  );

  const methods = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (changePasswordSuccess) {
      toast.success("Амжилттай солигдлоо");
      dispatch(changedPasswordSuccess());
    }
  }, [changePasswordSuccess, dispatch]);

  const onSubmit = (data) => {
    if (changePasswordLoading) return;

    if (data.newPassword === data.confirmPassword) {
      dispatch(
        changePasswordThunk({
          oldPassword: data.oldPassword,
          newPassword: data.newPassword,
        })
      );
    } else {
      toast.warning("Шинээр оруулсан нууц үгүүд хоорондоо таарахгүй байна!");
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="oldPassword"
          >
            Одоогийн нууц үг
          </label>
          <Input
            id="oldPassword"
            label=""
            size="md"
            type="password"
            {...register("oldPassword", { required: true })}
            className="w-full"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="newPassword"
          >
            Шинэ нууц үг
          </label>
          <Input
            id="newPassword"
            label=""
            type="password"
            size="md"
            {...register("newPassword", {
              required: true,
              minLength: 8,
              validate: {
                hasUpperCase,
                hasLowerCase,
                hasNumber,
                hasSpecialChars,
              },
            })}
            className="w-full"
          />
          <p
            className={twMerge([
              "text-error text-xs pl-2 mt-1",
              validateMinLength(watch("newPassword")) && "text-success",
            ])}
          >
            *Хамгийн багадаа 8 тэмдэгт
          </p>
          <p
            className={twMerge([
              "text-error text-xs pl-2 mt-1",
              hasUpperCase(watch("newPassword")) && "text-success",
            ])}
          >
            *Том үсэг агуулсан байх
          </p>
          <p
            className={twMerge([
              "text-error text-xs pl-2 mt-1",
              hasLowerCase(watch("newPassword")) && "text-success",
            ])}
          >
            *Жижиг үсэг агуулсан байх
          </p>
          <p
            className={twMerge([
              "text-error text-xs pl-2 mt-1",
              hasNumber(watch("newPassword")) && "text-success",
            ])}
          >
            *Тоо агуулсан байх
          </p>
          <p
            className={twMerge([
              "text-error text-xs pl-2 mt-1",
              hasSpecialChars(watch("newPassword")) && "text-success",
            ])}
          >
            *Тусгай тэмдэгт агуулсан байх
          </p>
        </div>

        <div className="mb-4">
          <label
            className="block text-sm font-medium mb-1"
            htmlFor="confirmPassword"
          >
            Нууц үг баталгаажуулах
          </label>
          <Input
            id="confirmPassword"
            label=""
            size="md"
            type="password"
            {...register("confirmPassword", { required: true })}
            className="w-full"
          />
        </div>

        <button
          type="submit"
          className="w-full bg-primary text-white font-bold py-2 rounded-lg hover:bg-primary-dark transition-colors"
        >
          {changePasswordLoading ? (
            <div className="flex justify-center">
              <PulseLoader
                color="#BBC1C9"
                speedMultiplier={0.7}
                loading={true}
                size={12}
              />
            </div>
          ) : (
            "Хадгалах"
          )}
        </button>
      </form>
    </FormProvider>
  );
};

export default ChangePassword;
