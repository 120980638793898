import axios from "axios";

const headers = {
  "Content-Type": "application/json",
  "Accept": "*/*",
  'Cache-Control': 'no-cache', // browser cache disable
  // 'Pragma': 'no-cache', // browser cache disable
  // 'Expires': '0', // browser cache disable
}

export default axios.create({
  // baseURL: process.env.REACT_APP_API_URL,
  // baseURL: '/api/',
  headers,
})
