import { twMerge } from "tailwind-merge";

const Loader = ({ isLoading }) => {
  return (
    <div
      className={twMerge([
        "flex justify-center items-center z-50 fixed top-0 bottom-0 right-0 left-0 bg-white opacity-50",
        !isLoading && "hidden ",
      ])}
    >
      <div className="w-8 h-8 p-[1px] border rounded-full bg-gradient-to-r from-primary to-stroke animate-[spin_.8s_linear_0s_infinite]">
        <div className="w-full h-full rounded-full bg-white"></div>
      </div>
    </div>
  );
};

export default Loader;
