import { useState } from "react";
import Modal from "../../../base-components/Modal";
import { closeMoneyModal, setMoneyStep } from "../../../redux/slices/savings";
import { dispatch, useSelector } from "../../../redux/store";
import Payment from "./payment";
import Base64ImageDisplay from "./qr";
import Account from "./account";

const MoneyAddModal = () => {
  const [paymentMethod, setPaymentMethod] = useState("QPAY");

  const { step } = useSelector((state) => state.savings);
  const onClose = () => {
    dispatch(closeMoneyModal());
  };
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      //   title={"Мөнгө нэмэх"}
      withCloseButton={step === 1}
      withBackButton={step > 1} // BACK button-aa nuuh heregt bol ene hesgiig comment hiigeerei
      onBackClick={() => {
        dispatch(setMoneyStep(1));
      }}
      panelClassName="bg-white rounded-none"
    >
      {step === 1 && <Payment {...{ paymentMethod, setPaymentMethod }} />}
      {step === 2 && (
        <>
          {paymentMethod === "QPAY" && <Base64ImageDisplay />}
          {paymentMethod === "ACCOUNT" && <Account />}
        </>
      )}
      {/* {step === 1 && <SavingsInfo {...{ step, setStep: (step) => { dispatch(setStep(step)) } }} />}
            {step === 2 && <EnterEmail {...{ step, setStep: (step) => { dispatch(setStep(step)) } }} />}
            {step === 3 && <EmailOtpVerify {...{ step, setStep: (step) => { dispatch(setStep(step)) } }} />}
            {step === 4 && <RegisterForm {...{ step, setStep: (step) => { dispatch(setStep(step)) } }} />}
            {step === 5 && <SavingsForm {...{ step, setStep: (step) => { dispatch(setStep(step)) } }} />}
            {step === 6 && <Schedule {...{ step, setStep: (step) => { dispatch(setStep(step)) } }} />}
            {step === 7 && <Contract {...{ step, setStep: (step) => { dispatch(setStep(step)) } }} />} */}
    </Modal>
  );
};

export default MoneyAddModal;
