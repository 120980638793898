import React, { useEffect } from "react";
import Button from "../../../../base-components/Button";
import Typography from "../../../../base-components/Typography";
import Card from "../../../../components/Card";
import { dispatch, useSelector } from "../../../../redux/store";
import { getScheduleThunk } from "../../../../redux/slices/product";
import { height } from "@mui/system";
import { useWindowSize } from "../../../../hooks/windowSize";

const schedules = [
  { ognoo: "2024.12.01", dun: "200,000", huu: "10,000" },
  { ognoo: "2025.01.01", dun: "200,000", huu: "11,000" },
  { ognoo: "2025.02.01", dun: "200,000", huu: "12,000" },
];

const Schedule = ({ step, setStep }) => {
  const {
    schedule,
    scheduleLoading,
    product,
    selectedDuration,
    selectedAmount,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(
      getScheduleThunk({
        productId: product.productInfo.id,
        amountId: selectedAmount.id,
        durationId: selectedDuration.id,
      })
    );
  }, []);
  console.log("schedule, scheduleLoading", schedule, scheduleLoading);

  const { height } = useWindowSize();

  return (
    <>
      <Card className="mt-5 grid gap-5">
        <Typography variant="Title" className="text-primary">
          Хуримтлал нэмэх хуваарь
        </Typography>
        <div style={{ maxHeight: height - 350 }} className="overflow-auto">
          <div key={schedule.date} className="grid gap-2">
            {scheduleLoading
              ? "Уншиж байна"
              : schedule.map((schedule) => (
                  <div className="bg-gray-200 rounded-lg p-3 flex items-center justify-between">
                    <div className="flex items-center space-x-3">
                      <Typography variant="Label" className="text-gray">
                        Нэмэх дүн
                      </Typography>
                      <Typography>{schedule.amount}₮</Typography>
                    </div>
                    <Typography variant="Label">{schedule.date}</Typography>

                    {/* <div className="flex items-center space-x-3">
                    <Typography variant="Label" className="text-gray">
                      Хуримтлагдах хүү
                    </Typography>
                    <Typography>{schedule.huu}%</Typography>
                  </div> */}
                  </div>
                ))}
          </div>
        </div>
      </Card>
      <div className="flex items-center justify-center mt-5">
        <Button onClick={() => setStep(step + 1)}>Үргэлжлүүлэх</Button>
      </div>
    </>
  );
};

export default Schedule;
