import { forwardRef, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { ReactComponent as CalendarIcon } from "../../assets/icon/calendar.svg";
import Calendar from "../../base-components/Calendar";
import { TbChevronDown } from "react-icons/tb";
import Dropdown from "../../base-components/Dropdown";
import Typography from "../../base-components/Typography";

const Date = forwardRef((props, ref) => {
  const {
    disabled = false,
    label,
    date = dayjs(),
    className,
    onChange = () => {},
    minDate,
    maxDate,
    view = "day",
  } = props;
  const [selectedDate, setSelectedDate] = useState(date);

  const dropdownRef = useRef(null);

  const handleChangeDate = (date) => {
    onChange(date);
    setSelectedDate(date);
    dropdownRef?.current?.close();
  };

  return (
    <Dropdown
      ref={dropdownRef}
      target={
        <>
          {label && <Typography className="text-[10px]">{label}</Typography>}
          <div
            className={twMerge([
              "w-full text-sm text-primary border-stroke border p-4 shadow-sm rounded-lg font-medium tracking-wide bg-white",
              className,
            ])}
          >
            <div className="flex items-center">
              <CalendarIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span
                className={twMerge([
                  "block truncate sm:mx-4 mx-2",
                  disabled && "text-gray",
                ])}
              >
                {view === "day" &&
                  (date.format("YYYY/MM/DD") ||
                    selectedDate.format("YYYY/MM/DD"))}
                {view === "month" &&
                  (date.format("YYYY/MM") || selectedDate.format("YYYY/MM"))}
                {view === "year" &&
                  (date.format("YYYY") || selectedDate.format("YYYY"))}
              </span>
              <TbChevronDown
                size={window.innerWidth > 480 ? 26 : 20}
                className="ml-2 xs:block hidden"
              />
            </div>
          </div>
        </>
      }
      targetClassName="text-start"
    >
      <Calendar
        value={selectedDate}
        onChange={handleChangeDate}
        minDate={minDate}
        maxDate={maxDate}
        view={view}
      />
    </Dropdown>
  );
});

Date.prototype = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  className: PropTypes.string,
  placement: PropTypes.string,
  onChangeDate: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  view: PropTypes.string,
};

export default Date;
