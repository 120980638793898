import React, { useState, useRef } from "react";
import Picker from "react-month-picker";
import "react-month-picker/css/month-picker.css";

const CustomMonthPicker = ({ child, handleChange, startMonth }) => {
  const now = new Date();
  const [singleValue2, setSingleValue2] = useState(
    startMonth ?? {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
    }
  );

  const pickerRef = useRef(null);

  const handleClickMonthBox = () => {
    pickerRef.current.show();
  };

  const handleAMonthChange = (year, month) => {
    setSingleValue2({ year, month });
    handleChange({ year, month });
    pickerRef.current.dismiss();
  };

  const pickerLang = {
    months: [
      "1 сар",
      "2 сар",
      "3 сар",
      "4 сар",
      "5 сар",
      "6 сар",
      "7 сар",
      "8 сар",
      "9 сар",
      "10 сар",
      "11 сар",
      "12 сар",
    ],
    from: "From",
    to: "To",
  };

  const makeText = (m) => {
    if (m && m.year && m.month) {
      return `${m.year}/${pickerLang.months[m.month - 1]}`;
    }
    return "?";
  };

  return (
    <div className="App d-flex justify-content-center p-1">
      <Picker
        ref={pickerRef}
        years={{
          min: { year: 2016, month: 2 },
          max: { year: now.getFullYear(), month: now.getMonth() + 1 },
        }}
        value={singleValue2}
        lang={pickerLang.months}
        theme="light"
        onChange={handleAMonthChange}
        onDismiss={() => {}}
      >
        <div onClick={handleClickMonthBox}>
          {child}
          {/* <label>{makeText(singleValue2)}</label> */}
        </div>
      </Picker>
    </div>
  );
};

export default CustomMonthPicker;
