import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import Button from "../../base-components/Button";
import Input from "../../base-components/Input";
import { dispatch, useSelector } from "../../redux/store";
import { hideError, loginThunk } from "../../redux/slices/auth";
import Checkbox from "../../base-components/Checkbox";
import Typography from "../../base-components/Typography";

function Login() {
  const { loading, error, logged, isVerifyDevice } = useSelector((state) => state.auth);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm({
    defaultValues: {
      mobile: "",
      password: "",
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    setFocus("mobile", { shouldSelect: true });
  }, [setFocus]);

  const onSubmit = (data) => {
    let params = {
      username: data.mobile, //super-admin
      password: data.password, //test
    };
    dispatch(loginThunk({ params, navigate }));
  };
  useEffect(() => {

    if (logged == true) {
      return navigate("/home", { replace: true });
    }
    if (isVerifyDevice) {
      return navigate("/not-verify-device", { replace: true });

    }
  }, [logged, isVerifyDevice])
  return (
    // <RemindDevice />
    <>
      <Typography variant="Headline" size="md">
        Нэвтрэх
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-[333px] max-[500px]:w-full"
      >
        <div className="space-y-5 mt-8">
          <div>
            <Input
              id="phoneNumber"
              // label="Нэвтрэх нэр"
              placeholder="Нэвтрэх нэр"
              className="w-full"
              {...register("mobile", {
                required: true,
                onChange: (e) => {
                  dispatch(hideError());
                },
              })}
            />
            {errors?.mobile?.type === "required" && (
              <p className="text-error text-xs pl-2 mt-1">
                Нэвтрэх нэрээ оруулна уу!
              </p>
            )}
          </div>
          <div>
            <Input
              id="password"
              // label="Нууц үг"
              placeholder="Нууц үг"
              type="password"
              {...register("password", {
                required: true,
                maxLength: 50,
                onChange: (e) => {
                  dispatch(hideError());
                },
              })}
              className="w-full"
            />
            {errors?.password?.type === "required" && (
              <p className="text-error text-xs pl-2 mt-1">
                Нууц үгээ оруулна уу!
              </p>
            )}
          </div>
          {error && <p className="text-error text-xs pl-2 mt-1">{error}</p>}
        </div>
        <div className="mt-6 mb-5 grid gap-4">
          <Checkbox label="Намайг сана" id="remember_me" />
          {/* <Checkbox label="Хурууны хээ/Face ID ашиглах" id="face_id" /> */}
        </div>
        <Button type="submit" disabled={loading} className="w-full">
          Нэвтрэх
        </Button>
      </form>
      <Button
        variant="outlinePrimary"
        className="w-full mt-10"
        onClick={() => {
          navigate("/register", {
            state: { prevPath: window.location.pathname },
          });
        }}
      >
        Шинээр бүртгүүлэх
      </Button>
      <button
        onClick={() =>
          navigate("/recover-password", {
            state: { prevPath: window.location.pathname },
          })
        }
        className="outline-none underline flex justify-center w-full my-8 text-gray text-sm"
      >
        Нууц үгээ мартсан
      </button>
    </>
  );
}

export default Login;
