import Service from "./BaseService";

const checkRegisterDan = (data, config) => Service.post(`/api/v1/register/add`, data, { ...config, nonLoading: true }, 8080);
const connectDan = (param, config) => Service.get(`/api/v1/dan?${param}`, { ...config, nonLoading: true }, 8080);
const danCheck = (config) => Service.get(`/api/v1/info/dan/check`, { ...config, nonLoading: true }, 8080);



const DanService = {
    checkRegisterDan,
    connectDan,
    danCheck
}

export default DanService;

