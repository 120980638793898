import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import LocalStorageBackend from "i18next-localstorage-backend";

export const locales = [
  {
    name: "MN",
    key: "mn",
  },
  {
    name: "EN",
    key: "en",
  },
];

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    fallbackLng: "mn",
    supportedLngs: locales.map((l) => l.key),
    load: "languageOnly",

    debug: true,
    backend: {
      backends: [LocalStorageBackend, HttpBackend],
      backendOptions: [],
      cacheHitMode: "refreshAndUpdateStore",
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      bindI18nStore: "added", // this way, when the HttpBackend delivers new translations (thanks to refreshAndUpdateStore), the UI gets updated
    },
  });

export default i18n;
