import React, { useEffect, useState } from "react";
import { Select } from "@headlessui/react";
import { useSelector } from "react-redux";
import Button from "../../../../base-components/Button";
import Typography from "../../../../base-components/Typography";
import Input from "../../../../base-components/Input";

import { dispatch } from "../../../../redux/store";
import {
  setSelectedAmount,
  setSelectedDuration,
} from "../../../../redux/slices/product";

const types = { ASSET: "Үнэт цаас", BOND: "Бонд", DEPOSIT: "Хуримтлал" };
const SavingsForm = ({ step, setStep }) => {
  const onContinue = () => {
    setStep(step + 1);
  };
  const { product, selectedDuration, selectedAmount } = useSelector(
    (state) => state.product
  );

  const [total, setTotal] = useState(0);
  const calculateTotal = () => {
    if (selectedDuration && selectedAmount) {
      var yearOfMonth =
        parseInt(selectedAmount.amount) +
        parseInt(
          selectedAmount.amount * (product.productInfo.monthlyInterest / 100)
        );

      setTotal(yearOfMonth * selectedDuration.duration);
    }
  };

  const handleChangeDuration = (e) => {
    const selectedIndex = parseInt(e.target.value, 10);
    const selectedOption = product.duration[selectedIndex];
    dispatch(setSelectedDuration(selectedOption));
  };

  const handleChangeAmount = (e) => {
    const selectedIndex = parseInt(e.target.value, 10);
    const selectedOption = product.amount[selectedIndex];
    console.log("iishee orjinuu123", selectedOption, selectedIndex);

    dispatch(setSelectedAmount(selectedOption));
  };

  useEffect(() => {
    calculateTotal();
  }, [selectedAmount, selectedDuration]);
  return (
    <div className="grid gap-5 mt-5">
      <div className="bg-white rounded-xl p-4 grid gap-4">
        <Input
          size="sm"
          value={"Хадгаламжийн төрөл : " + types[product.productInfo.type]}
          className="text-primary"
        />

        <div className="w-full outline-none border border-stroke rounded-md p-2 py-1.5">
          <Typography variant="Label">MNT</Typography>
        </div>
        <Select
          className={
            "w-full outline-none border border-stroke rounded-md p-2 py-1.5 cursor-pointer text-sm text-primary"
          }
          value={
            product.duration.indexOf(selectedDuration) !== -1
              ? product.duration.indexOf(selectedDuration)
              : ""
          } // Use the index for the value
          onChange={handleChangeDuration}
        >
          {product.duration.map((jil, index) => (
            <option key={jil.duration} value={index}>
              <Typography>{jil.duration} сар</Typography>
            </option>
          ))}
        </Select>
        <Select
          className={
            "w-full outline-none border border-stroke rounded-md p-2 py-1.5 cursor-pointer text-sm text-primary"
          }
          value={
            product.amount.indexOf(selectedAmount) !== -1
              ? product.amount.indexOf(selectedAmount)
              : ""
          } // Use the index for the value
          onChange={handleChangeAmount}
        >
          {product.amount.map((jil, index) => (
            <option key={jil.amount} value={index} className="text-primary">
              <Typography>{jil.amount} </Typography>
            </option>
          ))}
        </Select>

        <Input
          size="sm"
          value={"Төлөлт хийх өдөр : " + product.productInfo.dayOfMonth}
          className="text-primary"
        />
        <Input
          size="sm"
          value={"Сарын хүү : " + product.productInfo.monthlyInterest + " %"}
          className="text-primary"
        />
        <Input
          size="sm"
          value={`Сүүлд хуримтлагдах дүн: ${total}`}
          className="text-primary"
        />
      </div>
      <div className="flex justify-center">
        <Button onClick={onContinue}>Үргэлжлүүлэх</Button>
      </div>
    </div>
  );
};

export default SavingsForm;
