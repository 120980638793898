import { useEffect, useState } from "react";
import Typography from "../../base-components/Typography";
import { dispatch, useSelector } from "../../redux/store";
import { newsThunk } from "../../redux/slices/profile";

const Info = () => {
  const { news } = useSelector((state) => state.profile);
  const [selectedNews, setSelectedNews] = useState(null); // Modal-д харуулах мэдээлэл
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal-н харагдах эсэх

  useEffect(() => {
    dispatch(newsThunk());
  }, []);

  const openModal = (newsItem) => {
    setSelectedNews(newsItem);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedNews(null);
    setIsModalOpen(false);
  };

  if (!news || (news && news.length === 0)) {
    return <></>;
  }

  return (
    <div className="shadow-card rounded-base mt-6 p-4">
      <Typography variant="Title">Мэдээлэл</Typography>
      <div className="grid gap-2.5 mt-4">
        {news.map((item, idx) => (
          <div style={{ cursor: "pointer" }} key={idx} onClick={() => openModal(item)} className="border-t border-stroke p-2">
            <Typography className="text-font-third">
              {item.title}
            </Typography>
            {/* <button
              onClick={() => openModal(item)}
              className="text-primary mt-2 underline"
            >
              Дэлгэрэнгүй
            </button> */}
          </div>
        ))}
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg shadow-lg w-96 p-6 relative">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeModal}
            >
              ✖
            </button>
            {selectedNews && (
              <>

                <Typography variant="Title">{selectedNews.title}</Typography>
                {selectedNews.imagePath && (
                  <img
                    src={"https://geregefund.mn" + selectedNews.imagePath}
                    alt="news"
                    className="rounded-md mb-4 w-full h-auto"
                  />
                )}
                <Typography
                  variant="Body"
                  className="text-font-secondary mt-4"
                  dangerouslySetInnerHTML={{ __html: selectedNews.description }}
                ></Typography>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Info;
