import Service from "./BaseService";

const getOtpBySms = (mobile, config) => Service.get(`/dynamicInsurance/get-otp-by-sms/?mobile=${mobile}`, { ...config, nonLoading: true });

const checkOtp = (payload, config) => Service.post(`/dynamicInsurance/check-otp`, payload, { ...config, nonLoading: true });

const createWebUser = (payload, config) => Service.post(`/dynamicInsurance/sa-web-user`, payload, { ...config, nonLoading: true });

const checkPhoneNumber = (payload, config,) => Service.post(`/api/v1/cust/register/check`, payload, { ...config, nonLoading: true }, 8080);

// const otpVerify = (payload, config,) => Service.get(`/api/v1/cust/register/otp/verify`, { ...config, nonLoading: true }, 8080);

const otpVerify = ({ phoneNumber, otpValue }, config) => Service.get(`/api/v1/cust/register/otp/verify?phoneNumber=${phoneNumber}&otpValue=${otpValue}`, { ...config, nonLoading: true }, 8080);

const otpResend = (phoneNumber, config,) => Service.get(`/api/v1/cust/register/otp/resend?phoneNumber=${phoneNumber}`, { ...config, nonLoading: true }, 8080);

const resetPassword = (payload, config,) => Service.post(`/api/v1/cust/register/pass/add`, payload, { ...config, nonLoading: true }, 8080);


const resetPass = ({ phoneNumber }, config) => Service.get(`/api/v1/cust/reset/pass?phoneNumber=${phoneNumber}`, { ...config, nonLoading: true }, 8080);



const ForgotService = {
    getOtpBySms,
    checkOtp,
    createWebUser,
    checkPhoneNumber,
    otpVerify,
    otpResend,
    resetPassword,
    resetPass
}

export default ForgotService;