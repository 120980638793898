
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { dispatch, useSelector } from "../../redux/store";
import { getProductDetail } from "../../redux/slices/product";
const ProductDetail = () => {

    const { product, productLoading } = useSelector(state => state.product)
    const { id } = useParams();
    useEffect(() => {
        if (id) {
            dispatch(getProductDetail(id))
        }
    }, [id])
    console.log("didiididididid", id);
    if (productLoading) return <>Loading...</>
    if (product)
        return (
            <>
                {product.productInfo.name}
            </>
        );
    else return <>Бүтээгдэхүүний мэдээлэл байхгүй байна</>
};

export default ProductDetail;
