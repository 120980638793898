import {
  Stepper as CustomStepper,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  styled,
} from "@mui/material";
import { LuCheck } from "react-icons/lu";

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderTopStyle: "dashed",
  },
}));

const CustomStepIconRoot = styled("div")(({ theme }) => ({
  border: "1px solid #03596A",
  color: "#03596A", //
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 28,
  width: 37,
  borderRadius: 10,
  "& .QontoStepIcon-completedIcon": {
    color: "#03596A",
    zIndex: 1,
    fontSize: 18,
  },
  ...theme.applyStyles("dark", {
    color: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        backgroundColor: "#03596A",
        color: "#fff",
      },
    },
    {
      props: ({ ownerState }) => ownerState.completed,
      style: {
        backgroundColor: "#03596A",
        color: "#fff",
        "& .QontoStepIcon-completedIcon": {
          color: "#fff",
        },
      },
    },
  ],
}));

function CustomStepIcon(props) {
  const { active, completed, className, icon } = props;

  return (
    <CustomStepIconRoot
      ownerState={{ active, completed }}
      className={className}
    >
      {completed ? (
        <LuCheck className="QontoStepIcon-completedIcon" />
      ) : (
        <span className="text-sm">{icon}</span>
      )}
    </CustomStepIconRoot>
  );
}

const Stepper = ({ steps = [], activeStep, orientation }) => {
  return (
    <CustomStepper
      alternativeLabel
      activeStep={activeStep}
      connector={<CustomConnector />}
      orientation={orientation}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={CustomStepIcon} />
        </Step>
      ))}
    </CustomStepper>
  );
};

export default Stepper;
