import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { createSelectorHooks } from "auto-zustand-selectors-hook";
import { createMenu } from "./menuSlice";
import { createToken } from "./tokenSlice";
import { createLoader } from "./loaderSlice";
import { createUser } from "./userSlice";
import { createPath } from "./pathSlice";

const useStoreBase = create(
  devtools(
    persist(
      (...a) => ({
        ...createMenu(...a),
        ...createToken(...a),
        ...createLoader(...a),
        ...createUser(...a),
        ...createPath(...a),
      }),
      {
        name: "online.agula",
        getStorage: () => sessionStorage,
      }
    )
  )
);

export const useStore = createSelectorHooks(useStoreBase);
