import { Select } from "@headlessui/react";
import Input from "../../../../../base-components/Input";
import Typography from "../../../../../base-components/Typography";
import { dispatch, useSelector } from "../../../../../redux/store";
import {
  setSelectedAmount,
  setSelectedDuration,
} from "../../../../../redux/slices/product";
import { useState } from "react";
import Button from "../../../../../base-components/Button";

const FirstStep = ({ step, setStep }) => {
  const { product, selectedDuration, selectedAmount } = useSelector(
    (state) => state.product
  );
  const [total, setTotal] = useState(0);

  const handleChangeDuration = (e) => {
    const selectedIndex = parseInt(e.target.value, 10);
    const selectedOption = product?.duration?.[selectedIndex];
    dispatch(setSelectedDuration(selectedOption));
  };

  const handleChangeAmount = (e) => {
    const selectedIndex = parseInt(e.target.value, 10);
    const selectedOption = product?.amount?.[selectedIndex];
    console.log("iishee orjinuu123", selectedOption, selectedIndex);

    dispatch(setSelectedAmount(selectedOption));
  };

  return (
    <div className="grid gap-3">
      <div className="grid gap-2">
        <Typography className="font-medium">Хуримтлалын нэр</Typography>
        <Input
          size="sm"
          value={product?.productInfo?.name}
          className="text-primary"
        />
      </div>

      <div className="grid gap-2">
        <Typography className="font-medium">Хуримтлалын хугацаа</Typography>
        <Select
          className="w-full outline-none border border-stroke rounded-md p-2 py-1.5 cursor-pointer text-sm text-primary"
          value={
            product?.duration?.indexOf(selectedDuration) !== -1
              ? product?.duration?.indexOf(selectedDuration)
              : ""
          } // Use the index for the value
          onChange={handleChangeDuration}
        >
          {product?.duration?.map((jil, index) => (
            <option key={jil.duration} value={index}>
              <Typography>{jil.duration} сар</Typography>
            </option>
          ))}
        </Select>
      </div>
      <div className="grid gap-2">
        <Typography className="font-medium">Хуримтлалын дүн</Typography>
        <Select
          className="w-full outline-none border border-stroke rounded-md p-2 py-1.5 cursor-pointer text-sm text-primary"
          value={
            product?.amount?.indexOf(selectedAmount) !== -1
              ? product?.amount?.indexOf(selectedAmount)
              : ""
          } // Use the index for the value
          onChange={handleChangeAmount}
        >
          {product?.amount?.map((jil, index) => (
            <option key={jil.amount} value={index} className="text-primary">
              <Typography>{jil.amount} </Typography>
            </option>
          ))}
        </Select>
      </div>
      <div className="grid gap-2">
        <Typography className="font-medium">Хуримтлал хийх өдөр</Typography>
        <Input
          size="sm"
          value={product?.productInfo?.dayOfMonth}
          className="text-primary"
        />
      </div>
      <div className="grid gap-2">
        <Typography className="font-medium">Сарын хүү</Typography>
        <Input
          size="sm"
          value={product?.productInfo?.monthlyInterest + " %"}
          className="text-primary"
        />
      </div>
      <div className="grid gap-2">
        <Typography className="font-medium">
          Хугацааны эцэст хуримтлагдах дүн
        </Typography>
        <Input size="sm" value={total} className="text-primary" />
      </div>
      <Button size="sm" className="mt-6" onClick={() => setStep(step + 1)}>
        Үргэлжлүүлэх
      </Button>
    </div>
  );
};

export default FirstStep;
