import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Card from "../../../../components/Card";

import Button from "../../../../base-components/Button";
import { dispatch, useSelector } from "../../../../redux/store";
import { changeOrderSuccess, closeProductModal, createOrderThunk } from "../../../../redux/slices/product";
import Input from "../../../../base-components/Input";
import { Controller, useForm } from "react-hook-form";
import Checkbox from "../../../../base-components/Checkbox";
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

const Contract = ({ step, setStep }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setFocus,
    setError,
  } = useForm();
  const navigate = useNavigate();
  const { product, selectedAmount, selectedDuration, createOrderSuccess, createOrderLoading } = useSelector((state) => state.product);

  // const [width, setWidth] = useState(600); // Default width
  // const [height, setHeight] = useState(400); // Default height

  const onSubmit = (data) => {
    console.log(data, "data");
    // window.alert("END PASS-AA SHALGANA");
    //  END PASS-AA SHALGANA

    dispatch(createOrderThunk({
      password: data.password,
      durationId: selectedDuration.id,
      amountId: selectedAmount.id,
      productId: product.productInfo.id
    }, navigate))
    // if (data.password !== "") {
    //   setError("password", { type: "custom", message: "Нууц үг буруу байна" });
    // }

    // setStep(step + 1);
    // dispatch(closeProductModal());
    // navigate("/savings", { replace: true });
    // toast.success("Амжилттай хуримтлал үүслээ!");
  };

  useEffect(() => {
    if (createOrderSuccess) {
      navigate("/savings", { replace: true });
      toast.success("Амжилттай хуримтлал үүслээ!");
      dispatch(changeOrderSuccess())
    }
  }, [createOrderSuccess])

  const layoutPluginInstance = defaultLayoutPlugin();
  const pdfUrl = `${process.env.PUBLIC_URL}/geree.pdf`;

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mt-5 grid gap-5">
      {/* PDF Viewer */}
      <Card>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <div
            style={{
              height: `${400}px`,
            }}
            className="border rounded-md overflow-hidden"
          >

            {
              product?.productInfo?.pdfPath && <Viewer
                fileUrl={

                  pdfUrl
                }
                plugins={[layoutPluginInstance]}
              />
            }



          </div>
        </Worker>
        <Controller
          control={control}
          name="approveContract"
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value } }) => (
            <div className="py-4">
              <Checkbox
                checked={value}
                onChange={(e) => {
                  console.log(e, "e");
                  if (e.target.checked) {
                    setTimeout(() => {
                      setFocus("password");
                    }, 100);
                  }
                  onChange(e.target.checked);
                }}
                label="Гэрээний нөхцөлийг зөвшөөрч байна"
              />
            </div>
          )}
        />
        {/* {errors?.approveContract && <Typography>aaaaaaa</Typography>} */}
        {watch("approveContract") && (
          <Input
            {...register("password", {
              required: true,
            })}
            label="Нууц үгээ оруулна уу"
            type="password"
          />
        )}
      </Card>

      {/* Confirm Button */}
      <div className="flex justify-center mt-5">
        <Button type="submit" disabled={createOrderLoading}>
          Баталгаажуулах
        </Button>
      </div>
    </form>
  );
};

export default Contract;
