import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../base-components/Input";
import Button from "../../base-components/Button";
import Typography from "../../base-components/Typography";
import { toast } from "react-toastify";

let currentOtpIndex = 0;
const ChangeMobile = () => {
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      newMobile: "",
      otp: "",
    },
  });
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const [showOtp, setShowOtp] = useState(false);

  const otpRef = useRef(null);

  useEffect(() => {
    setActiveOtpIndex(0);
  }, []);

  useEffect(() => {
    otpRef.current?.focus();
  }, [activeOtpIndex]);

  const verifyCode = (param) => {
    console.log("param123", param);
    // dispatch(
    //   emailOtpVeriyThunk({
    //     email,
    //     otpValue: param,
    //   })
    // );
  };
  const handleChange = ({ target }) => {
    // dispatch(hideOtpError(null));
    const { value } = target;
    const newOtp = [...otp];
    newOtp[currentOtpIndex] = value.substring(value.length - 1);

    if (!value) setActiveOtpIndex(currentOtpIndex - 1);
    else setActiveOtpIndex(currentOtpIndex + 1);

    setOtp(newOtp);
    let param = newOtp.toString().replace(/,/g, "");
    if (newOtp.filter((x) => x !== "").length === 6) {
      verifyCode(param);
    }
  };
  const handleKeyDown = ({ key }, index) => {
    currentOtpIndex = index;
    if (key === "Backspace") setActiveOtpIndex(currentOtpIndex - 1);
  };

  const onContinue = () => {
    setShowOtp(true);
  };

  const onSubmit = (data) => {
    console.log(data, "data");
    toast.success("Амжилттай шинэчлэгдлээ");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-4">
        <Input
          id="newMobile"
          label="Шинэ утасны дугаараа оруулна уу"
          size="md"
          {...register("newMobile", { required: true })}
          className="w-full"
        />
      </div>

      {showOtp ? (
        <div>
          <Typography type="softPrimary" className="max-w-[333px]">
            Таны {watch("newMobile")} хаягт 6 оронтой код илгээгдлээ
          </Typography>
          <div className="flex items-center space-x-2">
            {otp.map((_, index) => (
              <React.Fragment key={index}>
                <Input
                  ref={index === activeOtpIndex ? otpRef : null}
                  type={index > 3 ? "text" : "number"}
                  className="sm:w-14 sm:h-14 w-10 h-10 rounded py-0 max-sm:p-2 text-center sm:text-xl"
                  value={otp[index]}
                  onChange={(e) => handleChange(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                />
              </React.Fragment>
            ))}
          </div>
          <Button type="submit" className="mt-4">
            Баталгаажуулах
          </Button>
        </div>
      ) : (
        <Button loading={false} onClick={onContinue}>
          Үргэлжлүүлэх
        </Button>
      )}
    </form>
  );
};

export default ChangeMobile;
