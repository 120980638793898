import React from "react";
import { useNavigate } from "react-router-dom";
import { TbChevronLeft } from "react-icons/tb";
import { Doughnut } from "react-chartjs-2"; // Import Doughnut for the donut chart
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  LinearScale,
} from "chart.js";
import GradientAreaChart from "../../gradient_chart";
import Button from "../../../../base-components/Button";
import Typography from "../../../../base-components/Typography";
import Statement from "./Statement";

// Register the necessary components
ChartJS.register(Title, Tooltip, Legend, ArcElement, LinearScale);

const MySavings = () => {
  const navigate = useNavigate();

  const donutData = {
    labels: ["Хаан банк", "Голомт банк", "Хас банк"],
    datasets: [
      {
        data: [50, 30, 20],
        backgroundColor: ["#03596A", "#07869E", "#1E3539"],
        hoverBackgroundColor: ["#066173", "#088BA4", "#014451"],
      },
    ],
  };

  // Options for the Donut chart
  const donutOptions = {
    maintainAspectRatio: false,
    cutout: "70%", // Adjusts the size of the hole in the center to create a donut shape
  };

  return (
    <div className="p-4">
      <Button
        variant="text"
        icon={<TbChevronLeft size={26} />}
        onClick={() => navigate("/home")}
      >
        Буцах
      </Button>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-2">
        <div className="col-span-1 space-y-6">
          <div className="p-6 rounded-lg shadow-card">
            <Typography
              variant="Headline"
              size="sm"
              className="font-normal mb-6"
            >
              Миний хуримтлал
            </Typography>
            <Typography variant="Headline" size="sm">
              0 ₮
            </Typography>
          </div>

          <div className="p-6 py-4 rounded-lg flex-grow shadow-card">
            <Typography>Хуримтлалын эзлэх хувь, мөнгөн дүнгээр</Typography>
            <div className="flex justify-center">
              <Doughnut
                data={donutData}
                options={donutOptions}
                width={200}
                height={200}
              />
            </div>
          </div>

          <div className="p-6 py-4 rounded-lg shadow-card">
            <Typography>6 сарын харьцуулалт</Typography>
            <GradientAreaChart />
          </div>
        </div>
        {/* Right Column */}
        <div className="col-span-1 md:col-span-2">
          <Statement />
        </div>
      </div>
    </div>
  );
};

export default MySavings;
