import React from "react";
import CardSection from "./cart";
import SavingsSummary from "./saving_summary";
import MySavings from "./savings/index";
import RecommendedProducts from "./products";
import Info from "./Info";
import ProductRecommendations from "./news";

const HomePage = () => {
  return (
    <div className="p-4">
      <MySavings />
      <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
        <RecommendedProducts />
      </div>
      <Info />
      {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <CardSection></CardSection>
        <SavingsSummary></SavingsSummary>
      </div> */}
    </div>
  );
};

export default HomePage;
