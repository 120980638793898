import React from "react";
import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";

// Register chart.js components
Chart.register(...registerables);

const GradientAreaChart = () => {
  const chartRef = React.useRef(null);

  const data = {
    labels: ["1", "2", "3", "4", "5", "6"],
    datasets: [
      {
        label: "Хөрөнгө оруулалт",
        data: [65, 59, 80, 81, 56, 55],
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const ctx = chart.ctx;

          const gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, "rgba(114, 63, 137, 0.8)"); // Darker top color
          gradient.addColorStop(1, "rgba(255,255,255,0.0)"); // Lighter bottom color

          return gradient;
        },
        borderColor: "rgba(114, 63, 137, 1)", // Border color matching end color
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide legend
      },
      title: {
        display: false, // Hide title
      },
    },
    scales: {
      x: {
        ticks: {
          display: false, // Hide ticks on x-axis
        },
        grid: {
          display: false, // Hide grid lines on x-axis
        },
      },
      y: {
        beginAtZero: true,
        display: false, // Hide y-axis entirely
        ticks: {
          display: false, // Hide ticks on y-axis (even if axis is shown)
        },
        grid: {
          display: false, // Hide grid lines on y-axis
        },
      },
    },
  };

  return <Line ref={chartRef} options={options} data={data} />;
};

export default GradientAreaChart;
