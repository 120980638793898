import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./assets/css/app.css";
// redux
import { store } from "./redux/store";
//redux import
import { Provider as ReduxProvider } from "react-redux";
import ThemeProvider from "./contexts/ThemeContext";
import LocaleProvider from "./contexts/LocaleContext";

const root = createRoot(document.getElementById("root"));
root.render(
  <ReduxProvider store={store}>
    {/* <React.StrictMode> */}
    <ThemeProvider>
      <LocaleProvider>
        <App />
      </LocaleProvider>
    </ThemeProvider>
    {/* </React.StrictMode> */}
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
