import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";
import Typography from "../Typography";
import { ClipLoader } from "react-spinners";

const Button = forwardRef((props, ref) => {
  const {
    loading,
    disabled,
    className,
    variant = "primary",
    size = "lg",
    icon,
    textSize = "lg",
    type = "button",
  } = props;

  const generalStyles = [
    "px-4 text-white rounded-lg flex justify-center items-center font-medium tracking-[.25px] outline-none space-x-2 disabled:cursor-not-allowed",
  ];

  // Sizes
  const small = ["text-xs py-3 h-10"];
  const large = ["text-sm py-3.5 h-12"];

  // Main color
  const primary = [
    "bg-primary", // Default
    "focus:bg-primary-focus focus:text-white/[.64]", // On Focus
    "[&:hover:not(:disabled)]:bg-primary/90 [&:hover:not(:disabled)]:text-white/[.64]", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-inactive", // Disabled
  ];
  const secondary = [
    "bg-secondary", // Default
    "focus:bg-secondary-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-hover", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-inactive", // Disabled
  ];

  // Outline
  const outlinePrimary = [
    "border border-stroke bg-white text-primary", // Default
    "focus:bg-primary-soft-while-pressing focus:text-gray", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-soft-while-pressing [&:hover:not(:disabled)]:text-gray", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-inactive", // Disabled
  ];
  const outlineSecondary = [
    "border border-stroke-secondary-20 bg-white text-font-secondary", // Default
    "focus:bg-secondary-soft-focus focus:border-stroke-secondary-12", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-soft-while-pressing [&:hover:not(:disabled)]:border-stroke-secondary-12", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-inactive disabled:border-stroke-secondary-12", // Disabled
  ];

  // Soft color
  const softPrimary = [
    "bg-primary-soft text-white/[.64]", // Default
    "focus:bg-primary-soft-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-primary-soft-hover", // On hover and not disabled
    "disabled:bg-primary-disabled disabled:text-inactive", // Disabled
  ];

  const softSecondary = [
    "bg-secondary-soft text-font-secondary", // Default
    "focus:bg-secondary-soft-focus", // On Focus
    "[&:hover:not(:disabled)]:bg-secondary-soft-hover", // On hover and not disabled
    "disabled:bg-secondary-disabled disabled:text-inactive", // Disabled
  ];

  const text = ["border-none bg-white text-primary h-auto p-0"];

  return (
    <button
      {...props}
      ref={ref}
      type={type}
      className={twMerge([
        generalStyles,
        size == "sm" && small,
        size == "lg" && large,
        variant === "text" && text,
        variant === "primary" && primary,
        variant === "secondary" && secondary,
        variant === "outlinePrimary" && outlinePrimary,
        variant === "outlineSecondary" && outlineSecondary,
        variant === "softPrimary" && softPrimary,
        variant === "softSecondary" && softSecondary,
        className,
      ])}
    >
      {loading ? (
        <div className={twMerge(["justify-center hidden", loading && "flex"])}>
          <ClipLoader
            color="#BBC1C9"
            speedMultiplier={0.7}
            loading={loading}
            size={32}
          />
        </div>
      ) : (
        <>
          {icon}
          <Typography
            variant="Label"
            size={textSize}
            color={variant === "primary" && "text-white"}
          >
            {props.children}
          </Typography>
        </>
      )}
    </button>
  );
});

export default Button;
