import { LuFileText } from "react-icons/lu";
import Button from "../../../base-components/Button";
import Typography from "../../../base-components/Typography";
import { useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { useMediaQuery } from "../../../hooks/mediaQuery";
import { useEffect } from "react";
import { dispatch, useSelector } from "../../../redux/store";
import { totalThunk } from "../../../redux/slices/profile";
import { getOrderListThunk } from "../../../redux/slices/savings";

const savingsData = [
  {
    id: "12345678",
    bank: "Хаан банк",
    amount: 500000,
    interestAccrued: 30000,
    interestRate: 5,
  },
  {
    id: "12345679",
    bank: "Голомт банк",
    amount: 300000,
    interestAccrued: 20000,
    interestRate: 5,
  },
  {
    id: "123456710",
    bank: "Хас банк",
    amount: 200000,
    interestAccrued: 10000,
    interestRate: 5,
  },
  {
    id: "123456711",
    bank: "Хаан банк",
    amount: 500000,
    interestAccrued: 30000,
    interestRate: 5,
  },
];

const imgUrl =
  "https://i.pinimg.com/enabled_hi/564x/d9/57/a4/d957a459a1434e9235b320fb37ae5773.jpg";

const MySavings = () => {

  useEffect(() => {
    dispatch(getOrderListThunk());
  }, []);
  var totalSavings = 0;
  const navigate = useNavigate();
  const { total } = useSelector(state => state.profile)

  const { orders } = useSelector(state => state.savings)
  useEffect(() => {
    dispatch(totalThunk())
  }, [])
  if (total && total.length > 0) {
    totalSavings = (total[0].totalAmountMonth ?? 0) + (total[0].totalLastBalance ?? 0)
  }

  console.log("total total", total);
  return (
    <div className="flex max-sm:flex-col items-center gap-6 sm:h-60">
      <div className="relative shadow-card rounded-base px-6 py-8 sm:w-auto lg:w-96 w-full h-full">
        <Typography
          variant="Headline"
          size="sm"
          className="font-normal max-md:text-xl"
        >
          Миний хуримтлал
        </Typography>
        <Typography variant="Headline" size="sm" className="max-md:text-xl">
          {totalSavings.toLocaleString() ?? 0} ₮
        </Typography>
        <Button
          size="sm"
          icon={<LuFileText size={20} />}
          variant="outlinePrimary"
          className="absolute bottom-4 right-4"
          onClick={() => {
            navigate("/home/my-savings", { replace: true });
          }}
        >
          Дэлгэрэнгүй
        </Button>
      </div>
      <div className="shadow-card rounded-base px-6 py-7 h-full sm:max-h-60 max-h-[17rem] overflow-auto grow max-sm:w-full ">
        {orders && orders?.length > 0 ? (
          <div className="grid gap-4">
            {orders?.map((data) => (
              <div
                key={data.id}
                className="border border-[#F5F5F5] bg-card-base rounded-base md:px-6 p-4 flex items-center justify-between min-w-56"
              >
                <div className="">
                  <div
                    className={twMerge([
                      "w-20 h-14 rounded-base",
                      !imgUrl && "bg-[#D9D9D9]",
                    ])}
                  >
                    {imgUrl && (
                      <img
                        src={"https://geregefund.mn" +
                          data.imagePath}
                        className="w-full h-full rounded-base"
                      />
                    )}
                  </div>
                  <p>{data.productName}</p>
                </div>
                <div>
                  <Typography
                    variant="Title"
                    className="font-semibold max-md:text-lg"
                  >
                    {data.amount.toLocaleString() + " "}₮
                  </Typography>
                  <Typography size="sm" className="opacity-75 underline">
                    Сар бүрийн: {data.dayOfMonth}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-full border border-[#F5F5F5] bg-card-base rounded-base p-6 flex items-center justify-center">
            <Typography
              variant="Title"
              size="md"
              className="font-normal max-w-60 text-center"
            >
              Одоогоор танд хуримтлал байхгүй байна
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default MySavings;
