import { useForm, FormProvider } from "react-hook-form";
import EnterEmailForm from "./Email";

const EnterEmail = () => {
    const methods = useForm();

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <EnterEmailForm />
                {/* <button type="submit">Submit</button> */}
            </form>
        </FormProvider>
    );
};

export default EnterEmail;
