import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { ReactComponent as ArrowLeft } from "../../assets/icon/arrow-left.svg";

import Button from "../../base-components/Button";
import IconButton from "../../base-components/IconButton";

import Mobile from "./mobile";
import Otp from "./otp";
import Password from "./password";

import { dispatch, useSelector } from "../../redux/store";
import {
  createCustomerThunk,
  initialStateRegister,
  setStep,
} from "../../redux/slices/register";

function Register() {
  const { step, registerSuccess, loading } = useSelector(
    (state) => state.register
  );
  const methods = useForm({
    defaultValues: {
      username: "",
      phoneNumber: "",
      otp: "",
      password: "",
      re_password: "",
    },
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (registerSuccess) {
      //show success dialog uzuuluu l endee
      toast.success("Бүртгэл амжилттай боллоо. Та нэвтэрч орно уу");
      navigate("/login");
    }
  }, [registerSuccess]);

  useEffect(() => {
    dispatch(initialStateRegister());
  }, []);

  const backToField = (e) => {
    e.preventDefault();
    if (step > 1) dispatch(setStep(step - 1));
    else navigate("/login");
  };

  /* Register API */
  const onSubmit = (data) => {
    if (
      methods.watch("re_password").toLowerCase() !==
      methods.watch("password").toLowerCase()
    ) {
      methods.setError("re_password", {
        type: "custom",
        message: "Нууц үг таарахгүй байна",
      });
      return;
    }
    console.log(data, "data-aaaaaaaaaaaaa");
    if (data && data.password) {
      dispatch(
        createCustomerThunk({
          phoneNumber: data.phoneNumber,
          password: data.password,
        })
      );
    }
  };

  return (
    <FormProvider {...methods}>
      <div className="flex mb-6">
        <IconButton size="sm" variant="softPrimary" onClick={backToField}>
          <ArrowLeft className="w-5 h-5" fill="#025867" />
        </IconButton>
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="xs:min-w-[333px] grid gap-8">
          {step === 1 && <Mobile />}
          {step === 2 && <Otp />}
          {step === 3 && (
            <>
              <Password loading={loading} />
              <Button type="submit" disabled={methods.watch("password") === ""}>
                Бүртгүүлэх
              </Button>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}

export default Register;
