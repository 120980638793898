import React, { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { PulseLoader } from "react-spinners";
import { twMerge } from "tailwind-merge";

import Input from "../../../base-components/Input";
import Typography from "../../../base-components/Typography";
import Button from "../../../base-components/Button";

import { useParams } from "react-router-dom";
import { dispatch, useSelector } from "../../../redux/store";
import {
  hideError,
  otpResendThunk,
  otpVeriyThunk,
} from "../../../redux/slices/register";

let currentOtpIndex = 0;
const Otp = () => {
  const { form } = useParams();
  const { error } = useSelector((state) => state.register);

  const {
    formState: { errors },
    getValues,
    setError,
    setValue,
  } = useFormContext();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(60);
  const [isActiveInterval, setIsActiveInterval] = useState(true);
  const [loading, setLoading] = useState(false);

  const otpRef = useRef(null);

  useEffect(() => {
    setActiveOtpIndex(0);
  }, []);

  useEffect(() => {
    let interval = null;
    if (isActiveInterval && timeLeft > 0) {
      interval = setInterval(() => {
        setTimeLeft((time) => time - 1);
      }, 1000);
    } else if (!isActiveInterval || timeLeft === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActiveInterval, timeLeft]);

  useEffect(() => {
    otpRef.current?.focus();
  }, [activeOtpIndex]);

  const verifyCode = (param) => {
    console.log("param123", param);
    dispatch(
      otpVeriyThunk({ phoneNumber: getValues("phoneNumber"), otpValue: param })
    );
  };
  const handleChange = ({ target }) => {
    dispatch(hideError(null));
    const { value } = target;
    const newOtp = [...otp];
    newOtp[currentOtpIndex] = value.substring(value.length - 1);

    if (!value) setActiveOtpIndex(currentOtpIndex - 1);
    else setActiveOtpIndex(currentOtpIndex + 1);

    setOtp(newOtp);
    let param = newOtp.toString().replace(/,/g, "");
    if (newOtp.filter((x) => x !== "").length === 6) {
      verifyCode(param);
    }
  };
  const handleKeyDown = ({ key }, index) => {
    currentOtpIndex = index;
    if (key === "Backspace") setActiveOtpIndex(currentOtpIndex - 1);
  };

  // const handleContinueClick = () => {
  //   // setStep(step + 1);
  // };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(
      2,
      "0"
    )}`;
  };

  return (
    <>
      <div>
        <Typography variant="Headline" size="md">
          OTP
        </Typography>
        <Typography type="softPrimary" className="max-w-[333px]">
          Таны {getValues("phoneNumber")} дугаарт 6 оронтой код илгээгдлээ
        </Typography>
      </div>
      <div className="flex items-center justify-center space-x-2">
        {otp.map((_, index) => (
          <React.Fragment key={index}>
            <Input
              ref={index === activeOtpIndex ? otpRef : null}
              type={index > 3 ? "text" : "number"}
              className="sm:w-14 sm:h-14 w-10 h-10 rounded py-0 max-sm:p-2 text-center text-base sm:text-xl"
              value={otp[index]}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
            />
          </React.Fragment>
        ))}
      </div>
      {errors?.otp?.type === "custom" && (
        <p className="text-error text-xs italic pl-2 mt-1">
          {errors?.otp?.message}
        </p>
      )}
      {error && <p className="text-error text-xs italic pl-2 mt-1">{error}</p>}
      <div
        className={twMerge(["justify-center mt-4 hidden", loading && "flex"])}
      >
        <PulseLoader
          color="#BBC1C9"
          speedMultiplier={0.7}
          loading={loading}
          size={12}
        />
      </div>
      <div className="flex items-center justify-between mb-20">
        <Typography className="text-center">{formatTime(timeLeft)}</Typography>
        {timeLeft == 0 && (
          <button
            className="outline-none [&:hover:not(:disabled)]:underline disabled:text-gray"
            onClick={() => {
              setTimeLeft(60);
              setIsActiveInterval(true);
              dispatch(
                otpResendThunk({ phoneNumber: getValues("phoneNumber") })
              );
            }}
            // disabled={timeLeft > 0}
          >
            <Typography>Ахин илгээх</Typography>
          </button>
        )}
      </div>
      {/* <div className="flex justify-center mt-10">
        <Button onClick={handleContinueClick}>Үргэлжлүүлэх</Button>
      </div> */}
    </>
  );
};

export default Otp;
