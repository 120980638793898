import { createSlice } from "@reduxjs/toolkit";
// utils
import { dispatch } from "../store";

//services
import ProductService from "../../services/ProductService";
// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: null,
  success: false,
  products: [],
  product: null,
  productLoading: false,
  product: null,
  modalOpen: false,
  schedule: [],
  scheduleLoading: false,
  scheduleError: null,
  selectedAmount: null,
  selectedDuration: null,
  step: 1,

  //input email
  otpLoading: false,
  otpResendSuccess: false,
  optError: null,
  email: null,

  //email verify
  otpVerifySuccess: false,
  otpLoading: false,
  otpError: null,

  //create order
  createOrderLoading: false,
  createOrderError: null,
  createOrderSuccess: false,
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    startDetailLoading(state) {
      state.productLoading = true;
    },
    stopDetailLoading(state) {
      state.productLoading = false;
    },
    startScheduleLoading(state) {
      state.scheduleLoading = true;
    },
    stopScheduleLoading(state) {
      state.scheduleLoading = false;
    },
    scheduleSuccess(state, action) {
      state.scheduleLoading = false;
      state.schedule = action.payload;
    },
    setSelectedAmount(state, action) {
      state.selectedAmount = action.payload;
    },
    setSelectedDuration(state, action) {
      state.selectedDuration = action.payload;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.productLoading = false;
      console.log("error ni yu ireed bna", action);
      state.error = action.payload;
    },
    initialStateProduct(state) {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.products = [];
    },

    getProductDataSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.products = action.payload;
    },
    getProductDetailSuccess(state, action) {
      state.product = action.payload;
      state.productLoading = false;
      state.error = null;
      state.success = false;
      state.step = 1;
      state.modalOpen = true;
      state.selectedAmount = action.payload.amount[0]
      state.selectedDuration = action.payload.duration[0]
    },
    hideError(state) {
      state.error = null;
      state.otpResendSuccess = false;
    },

    closeProductModal(state) {
      state.modalOpen = false;
      state.product = null;
    },
    productDetailSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.product = action.payload;
      state.modalOpen = true;
    },
    productDetailError(state, action) {
      state.loading = false;
      state.productLoading = false;
      state.modalOpen = false;
      state.error = action.payload;
    },
    scheduleDetailError(state, action) {
      state.scheduleLoading = false;
      state.schedule = [];
      state.scheduleError = action.payload;
    },

    hideOtpResendError(state) {
      state.error = null;
      state.otpResendSuccess = false;
    },

    //OTP verify success
    otpVerifySuccess(state, action) {
      state.loading = false;
      console.log("action ni yu ireed bna", action);
      state.success = true;
      state.error = null;
      state.step = state.step + 1;

    },
    //OTP resend success
    otpResendSuccess(state, action) {
      state.loading = false;
      console.log("action ni yu ireed bna", action);
      state.otpResendSuccess = true;
      state.error = null;
      state.step = state.step + 1;
      state.email = action.payload;
    },
    //Otp loading start
    startOtpLoading(state) {
      state.otpLoading = true;
    },
    //Otp loading stop
    stopOtpLoading(state) {
      state.otpLoading = false;
    },
    // HAS ERROR
    hasOtpError(state, action) {
      state.loading = false;
      console.log("error ni yu ireed bna", action);
      state.optError = action.payload;
    },
    setStep(state, action) {
      state.step = action.payload;
    },
    //verify loading
    emailOtpVerifySuccess(state) {
      state.otpVerifySuccess = false;
    },
    startVerifyOtpLoading(state) {
      state.otpLoading = true;
    },
    stopVerifyOtpLoading(state) {
      state.otpLoading = false;
    },
    otpVerifyError(state, action) {
      state.otpLoading = false;
      state.otpError = action.payload;
    },

    hideOtpError(state) {
      state.error = null;
    },
    startOrderLoading(state) {
      state.createOrderLoading = true;
    },
    stopOrderLoading(state) {
      state.createOrderLoading = false;
    },
    orderSuccess(state) {
      state.createOrderSuccess = true;
    },
    orderError(state, action) {
      state.createOrderLoading = false;
      state.orderError = action.payload;
    },
    changeOrderSuccess(state) {
      state.createOrderSuccess = false;
      state.modalOpen = false;
      state.product = null;
      state.step = state.step + 1;
      state.createOrderLoading = false;
    },

  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  openProductModal,
  closeProductModal,
  startLoading,
  stopLoading,
  hideError,
  hasError,
  initialStateProduct,
  setSelectedAmount,
  setSelectedDuration,
  hideOtpResendError,
  setStep,
  hideOtpError,
  changeOrderSuccess
} = slice.actions;

// // ----------------------------------------------------------------------

export function getProductList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ProductService.getProductList();
      console.log("getProductsList", response);
      dispatch(slice.actions.getProductDataSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.hasError(error));
      // dispatch(slice.actions.checkRegisterSuccess(phoneNumber));
    }
  };
}

export function getProductDetail(id) {
  return async () => {
    dispatch(slice.actions.startDetailLoading());
    try {
      const response = await ProductService.getProductDetail(id);
      console.log("getProductDetail", response);
      dispatch(slice.actions.getProductDetailSuccess(response?.responseData));
    } catch (error) {
      dispatch(slice.actions.productDetailError(error));
    }
  };
}

export function getScheduleThunk(body) {
  return async () => {
    dispatch(slice.actions.startScheduleLoading());
    try {
      const response = await ProductService.getSchedule(body);
      console.log("getProductDetail", response);
      dispatch(slice.actions.scheduleSuccess(response?.responseData));
    } catch (error) {
      dispatch(slice.actions.scheduleDetailError(error));
    }
  };
}


export function emailOtpVeriyThunk({ email, otpValue }) {
  console.log("body ni yu baina", email, otpValue);
  return async () => {
    dispatch(slice.actions.startVerifyOtpLoading());
    try {
      await ProductService.emailOtpVerify({ email, otpValue });
      dispatch(slice.actions.otpVerifySuccess());
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.otpVerifyError(error));
      console.log("error ni yu baina", error);
      // dispatch(slice.actions.otpVerifySuccess());
    }
  };
}

export function emailOtpResendThunk(email) {
  console.log("body ni yu baina", email);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await ProductService.emailOtpResend(email);
      dispatch(slice.actions.otpResendSuccess(email));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.hasOtpError(error));
      console.log("error ni yu baina", error);
      // dispatch(slice.actions.otpResendSuccess());
    }
  };
}



export function createOrderThunk(data) {
  console.log("body ni yu baina", data);
  return async () => {
    dispatch(slice.actions.startOrderLoading());
    try {
      await ProductService.createOrder(data);
      dispatch(slice.actions.orderSuccess());
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.orderError(error));
      console.log("error ni yu baina", error);
      // dispatch(slice.actions.otpResendSuccess());
    }
  };
}