import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { dispatch } from "../../../redux/store";
import {
  changeSelectedSchedule,
  paymentThunk,
} from "../../../redux/slices/savings";
import Typography from "../../../base-components/Typography";
import Checkbox from "../../../base-components/Checkbox";
import Button from "../../../base-components/Button";
import { Select } from "@headlessui/react";
import { PulseLoader } from "react-spinners";

const FinanceComponent = ({ paymentMethod, setPaymentMethod }) => {
  const { unpaidSchedule, schedule, selectSchedules, paymentLoading } = useSelector(
    (state) => state.savings
  );

  const getSelectedAllUnpaidSchedule = () => {
    const tempArray = [];
    if (selectSchedules && selectSchedules.length > 0)
      for (let i = 0; i < unpaidSchedule.length; i++) {
        const schedule = unpaidSchedule[i];
        if (selectSchedules.find(e => e.id == schedule.id)) {
          tempArray.push(schedule)
        }
      }
    console.log("unpaidSchedule.length == tempArray.length", selectSchedules, unpaidSchedule.length == tempArray.length, unpaidSchedule, tempArray);
    const isAllHaved = !unpaidSchedule.length == tempArray.length;
    if (isAllHaved) {
      if (selectSchedules && selectSchedules.length > 0) {
        if (selectSchedules.find(e => e.id == schedule[0].id)) {
          dispatch(changeSelectedSchedule(schedule[0]));
        }
      }

    }
    return isAllHaved;

  }
  const getAllAmount = () => {
    let allAmount = 0;
    console.log("selectSchedules", selectSchedules);
    selectSchedules.forEach(element => {
      console.log("ene ni yu bgaan", element.amount);
      allAmount += element.amount;
    });
    return allAmount;
  }

  const { t } = useTranslation();

  const totalAmount = 1000;

  return (
    <div className="">
      <div className="rounded-base py-3 px-4 border border-primary">
        {unpaidSchedule && unpaidSchedule.length > 0 && (
          <div className="grid gap-2">
            <Typography className="text-lg font-medium text-[#D70C0C]">
              Хоцорсон сарууд
            </Typography>
            <ul className="space-y-2 mb-4">
              {unpaidSchedule.map((element) => (
                // <li
                //   key={element.id} // Assuming each entry has a unique 'id'
                //   onClick={() => {
                //     dispatch(changeSelectedSchedule(element));
                //   }} // Pass the item to the click handler
                // >
                <Checkbox

                  id={element.id}
                  label={
                    <Typography className="text-lg font-normal">
                      {element.date + ": " + element.amount} ₮
                    </Typography>
                  }
                  checked={selectSchedules.findIndex((s) => s.id == element.id) > -1}
                  onChange={(e) => {
                    e.stopPropagation();
                    dispatch(changeSelectedSchedule(element));
                  }}
                />
                // </li>
              ))}
            </ul>
          </div>
        )}
        {schedule && (
          <div className="grid gap-2">
            <Typography className="text-lg font-medium">
              Хувиарт шилжүүлэг
            </Typography>
            <ul className="space-y-2 mb-4">
              {schedule.map((element) => (
                // <li
                //   key={element.id} // Assuming each entry has a unique 'id'
                //   onClick={(e) => {
                //     // e.stopPropagation();
                //     dispatch(changeSelectedSchedule(element));
                //   }} // Pass the item to the click handler
                // >
                //   <Checkbox
                //     disabled={getSelectedAllUnpaidSchedule()}
                //     id={element.id}
                //     label={
                //       <Typography className="text-lg font-normal">
                //         {element.date + ": " + element.amount} ₮
                //       </Typography>
                //     }
                //     checked={selectSchedules.findIndex((s) => s.id == element.id) > -1}
                //     onChange={(e) => {
                //       // e.stopPropagation();
                //       dispatch(changeSelectedSchedule(element));
                //     }}
                //   />
                // </li>
                <Checkbox
                  id={element.id}
                  disabled={getSelectedAllUnpaidSchedule()}
                  label={
                    <Typography className="text-lg font-normal">
                      {element.date + ": " + element.amount} ₮
                    </Typography>
                  }
                  checked={selectSchedules.findIndex((s) => s.id === element.id) > -1}
                  onChange={(e) => {
                    e.stopPropagation(); // Stop the click event from bubbling up
                    // Toggle the selection
                    dispatch(changeSelectedSchedule(element));
                  }}
                />
              ))}
            </ul>
          </div>
        )}
        <div className="w-full border-t border-primary"></div>
        <div className="mt-4 flex justify-between font-semibold">
          <Typography className="text-lg font-medium">
            {t("total_transfer_amount")}:
          </Typography>
          <Typography className="text-lg font-normal">
            {getAllAmount().toLocaleString()} ₮
          </Typography>
        </div>
      </div>
      <div className="my-4">
        <Typography className="text-lg font-medium mb-2">
          Төлбөрийн хэрэгсэл:
        </Typography>
        <Select
          value={paymentMethod}
          onChange={(e) => {
            setPaymentMethod(e.target.value);
          }}
          className="w-full outline-none border border-stroke rounded-md p-2 py-1.5 cursor-pointer text-sm text-primary"
        >
          <option value={"QPAY"}>
            <Typography>Qpay</Typography>
          </option>
          <option value={"ACCOUNT"}>
            <Typography>Данс</Typography>
          </option>
        </Select>
      </div>
      <Button

        onClick={() => {
          dispatch(
            paymentThunk({
              type: paymentMethod, // QPAY ,ACCOUNT
              scheduledList: selectSchedules.filter(e => e != null).map(e => e.id),
            })
          );
        }}
        className="w-full py-2 h-auto rounded-base"
      >
        <PulseLoader
          color="#BBC1C9"
          speedMultiplier={0.7}
          loading={paymentLoading}
          size={12}
        />
        {paymentLoading == false && "Мөнгө нэмэх"}
      </Button>
    </div>
  );
};

export default FinanceComponent;
