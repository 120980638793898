import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import { rootPersistConfig, rootReducer } from './rootReducer';

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(rootPersistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

const persistor = persistStore(store);

// Since JavaScript doesn't have TypeScript's type annotations,
// you don't need to declare `RootState` and `AppDispatch` types.

const { dispatch } = store;

const useDispatch = () => useAppDispatch();

const useSelector = useAppSelector;

export { store, persistor, dispatch, useSelector, useDispatch };
