import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Button from "../../base-components/Button";
import IconButton from "../../base-components/IconButton";

import { ReactComponent as ArrowLeft } from "../../assets/icon/arrow-left.svg";

import Otp from "./otp";
import Mobile from "./mobile";
import NewPassword from "./new-password";
import { dispatch, useSelector } from "../../redux/store";
import Typography from "../../base-components/Typography";
import { changeResetPassSuccess, forgotSetStep, resetPassAddThunk } from "../../redux/slices/forgot";

function RecoverPassword() {
  const methods = useForm({
    defaultValues: {
      mobile: "",
      otp: "",
      password: "",
      repeat_pass: "",
    },
  });
  const navigate = useNavigate();
  const { state } = useLocation();
  const { loading, step, resetPassSuccess } = useSelector(state => state.forgot);
  const backToField = (e) => {
    e.preventDefault();
    console.log("step ni yu baina : ", step);
    if (step > 1) dispatch(forgotSetStep(step - 1));
    else navigate(state.prevPath);
  };
  const onSubmit = (data) => {
    console.log(data, "data-aaaaaaaaaaaaa recover-pass");
    if (
      methods.watch("password").toLowerCase() !==
      methods.watch("repeat_pass").toLowerCase()
    ) {
      methods.setError("repeat_pass", {
        type: "custom",
        message: "Нууц үг таарахгүй байна",
      });
      return;
    }

    console.log("iishee orj bnuu", data);
    // resetPassAddThunk
    dispatch(resetPassAddThunk(data))

  };
  useEffect(() => {
    // Success uyed
    if (resetPassSuccess) {
      toast.success("Нууц үг амжилттай солигдлоо");
      dispatch(changeResetPassSuccess())
      navigate(state.prevPath);
    }

  }, [resetPassSuccess])

  return (
    <FormProvider {...methods}>
      <div className="flex mb-8">
        <IconButton size="sm" variant="softPrimary" onClick={backToField}>
          <ArrowLeft className="w-5 h-5" />
        </IconButton>
      </div>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="min-w-[333px] max-[500px]:w-full grid gap-8">
          {step !== 2 && (
            <Typography variant="Headline" size="md" className="mb-2">
              Нууц үг сэргээх
            </Typography>
          )}
          {step === 1 && <Mobile />}
          {step === 2 && <Otp />}
          {step === 3 && (
            <>
              <NewPassword {...{ loading }} />
              <div className="flex justify-end">
                <Button
                  type="submit"
                  className="w-1/2"
                  disabled={methods.watch("password") === ""}
                >
                  Шинэчлэх
                </Button>
              </div>
            </>
          )}
        </div>
      </form>
    </FormProvider>
  );
}

export default RecoverPassword;
