import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { LuChevronLeft, LuChevronRight } from "react-icons/lu";
import { twMerge } from "tailwind-merge";
import Typography from "../../../base-components/Typography";
import {
  closeProductModal,
  getProductDetail,
  getProductList,
} from "../../../redux/slices/product";
import { dispatch, useSelector } from "../../../redux/store";
import { useWindowSize } from "../../../hooks/windowSize";
import useElementSize from "../../../hooks/elemetSize";
import CreateSavings from "../create-savings";

const productImages = [
  "https://i.pinimg.com/enabled_hi/564x/d9/57/a4/d957a459a1434e9235b320fb37ae5773.jpg",
  "https://i.pinimg.com/enabled_hi/564x/90/bb/bb/90bbbbfd770343e1ce90810406460a8d.jpg",
  "https://i.pinimg.com/enabled_hi/564x/fd/19/18/fd191840e0448d130c824b516fdcd6ab.jpg",
  "https://i.pinimg.com/enabled_hi/564x/cc/1c/9f/cc1c9f11edcf64c3052a003ec29912bd.jpg",
];

const RecommendedProducts = () => {
  const navigate = useNavigate();
  const { products, modalOpen, productLoading } = useSelector(
    (state) => state.product
  );

  const sidebarEl = document.querySelector("#sidebar");
  const { width: sidebarWidth } = useElementSize(sidebarEl);
  const { width: windowWidth } = useWindowSize();

  const width = useMemo(() => {
    return windowWidth - sidebarWidth - 80;
  }, [windowWidth, sidebarWidth]);

  useEffect(() => {
    dispatch(getProductList());
  }, [dispatch]);

  let currentIndex = 0;
  const moveSlide = (step) => {
    currentIndex += step;

    if (currentIndex < 0) {
      currentIndex = products.length - 1;
    } else if (currentIndex >= products.length) {
      currentIndex = 0;
    }

    const slideWrapper = document.querySelector("#slide-wrapper");
    const slides = document.querySelectorAll("#slide");
    const slideWidth = slides[0].clientWidth;

    slideWrapper.style.transform = `translateX(-${currentIndex * (slideWidth + 16)
      }px)`;
  };
  if (!products || (products && products.length == 0))
    return <></>

  return (
    <>
      <div className="relative p-4 shadow-card mt-4 px-6 py-4 rounded-base">
        <Typography variant="Headline" size="sm">
          Санал болгож буй бүтээгдэхүүн
        </Typography>
        {productLoading ? (
          <div className="h-60 flex items-center justify-center bg-white/50">
            <BeatLoader size={13} />
          </div>
        ) : (
          <div
            style={{ width: width }}
            className="overflow-hidden mt-4 max-xs:w-auto"
          >
            <div
              id="slide-wrapper"
              className="flex items-center transition-all"
            >
              {products.map((product, index) => (
                <button
                  key={product.id}
                  id="slide"
                  className="outline-none rounded-base bg-card-base p-3.5 h-60 mx-2 grid gap-2"
                  onClick={() => {
                    dispatch(getProductDetail(product.id));
                    // navigate("product-detail");
                  }}
                >
                  <div
                    className={twMerge([
                      "w-80 h-40 rounded-base",
                      !product.imagePath && "bg-[#D9D9D9]",
                    ])}
                  >
                    {product.imagePath && (
                      <img
                        src={
                          product.imagePath
                            ? "https://geregefund.mn" +
                            product.imagePath.replace(
                              "https://admin.geregefund.mn",
                              ""
                            )
                            : productImages[index]
                        }
                        alt={product.name}
                        className="w-full h-full object-cover rounded-base"
                      />
                    )}
                  </div>
                  <p className="text-center font-semibold second-line-ellipsis">
                    {product.name}
                  </p>
                </button>
              ))}
            </div>
            <button
              onClick={() => moveSlide(-1)}
              className="outline-none absolute left-2 top-1/2 -translate-y-1/2 p-1 rounded-full bg-black/30 hover:bg-[#444]"
            >
              <LuChevronLeft size={25} color="white" />
            </button>
            <button
              onClick={() => moveSlide(1)}
              className="outline-none absolute right-2 top-1/2 -translate-y-1/2 p-1 rounded-full bg-black/30 hover:bg-[#444]"
            >
              <LuChevronRight size={25} color="white" />
            </button>
          </div>
        )}
      </div>
      {modalOpen && (
        <CreateSavings
          isOpen={true}
          onClose={() => dispatch(closeProductModal())}
        />
      )}
    </>
  );
};

export default RecommendedProducts;
