import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useStore } from "../../stores";
import { dispatch } from "../../redux/store";
import { checkLogin } from "../../redux/slices/auth";
import Loader from "../../components/Loader";

const Container = () => {
  const { logged, loading } = useSelector((state) => state.auth);
  const setPrevPath = useStore.useSetPrevPath();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("location.pathname]", location.pathname);
    dispatch(checkLogin(navigate));
  }, [logged]);

  useEffect(() => {
    if (location.pathname.match("insurance-process")) return;
    setPrevPath(location.pathname);
  }, [location.pathname]);

  return (
    <div className="text-primary h-full bg-primary">
      <Loader isLoading={loading} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
        // transition: Bounce,
      />
      <Outlet />
    </div>
  );
};

export default Container;
