import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import {
  TbAlertCircle,
  TbCircleCheck,
  TbInfoCircle,
  TbX,
} from "react-icons/tb";
import IconButton from "../IconButton";
import Typography from "../Typography";
import Modal from "../Modal";

const AlertModal = (props) => {
  const {
    isOpen,
    onClose = () => {},
    type,
    withIcon = false,
    title,
    closeButton = false,
    message,
    footer,
    size,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      placement="center"
      panelClassName="bg-white rounded-none"
      size={size}
    >
      {closeButton && (
        <IconButton
          variant="outlinePrimary"
          onClick={onClose}
          className="border-0 absolute top-4 right-4"
        >
          <TbX color="#0A213A" size={20} />
        </IconButton>
      )}
      <div className="p-5 text-center self-center">
        {withIcon && (
          <div className="flex justify-center">
            <div
              className={twMerge([
                "rounded-full p-4",
                type === "success" && "bg-success-soft",
                type === "warning" && "bg-warning-soft",
                type === "info" && "bg-info-soft",
                type === "error" && "bg-error-soft",
              ])}
            >
              {type === "success" && (
                <TbCircleCheck size={24} className="mx-auto" />
              )}
              {type === "warning" && (
                <TbAlertCircle size={24} className="mx-auto" />
              )}
              {type === "info" && (
                <TbInfoCircle size={24} className="mx-auto" />
              )}
              {type === "error" && <TbX size={24} className="mx-auto" />}
            </div>
          </div>
        )}
        {title && (
          <Typography
            variant="Headline"
            size="sm"
            className={twMerge(["mt-5", !message && "mb-12"])}
          >
            {title}
          </Typography>
        )}
        <div className="mt-2">{message}</div>
      </div>
      {footer && (
        <div className="pt-4 px-8 flex justify-center align-center">
          {footer}
        </div>
      )}
    </Modal>
  );
};

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  closeButton: PropTypes.bool,
  message: PropTypes.string,
  footer: PropTypes.element,
};

export default AlertModal;
