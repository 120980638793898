import React, { useEffect, useState } from "react";
import UserInfo from "./user_info";
import ChangePassword from "./change_password";
import ChangeMail from "./change_mail";
import ChangeMobile from "./change_mobile";
import AccessHistoryTable from "../access-history";
import RegisteredDevices from "./register-devices";
import ContactInfo from "./contact-info";
import TermsAndContract from "./terms-condition";
import { getUserInfo } from "../../redux/slices/profile";
import { dispatch } from "../../redux/store";

const Modal = ({ showModal, onClose, title, children }) => {
  if (!showModal) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-8 rounded-lg shadow-md w-11/12 md:w-1/3 relative">
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
          onClick={onClose}
          aria-label="Close"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <h2 className="text-lg font-bold mb-4">{title}</h2>
        {children}
        {/* <button
                    className="mt-4 bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-dark"
                    onClick={onClose}
                >
                    Хаах
                </button> */}
      </div>
    </div>
  );
};

const ProfileSettings = () => {
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const menus = [
    "Хувийн мэдээлэл",
    "Нууц үг солих",
    "Утасны дугаар солих",
    "Мэйл хаяг солих",
    "Хандалтын түүх",
    "Үйлчилгээний нөхцөл",
    "Бүртгэсэн төхөөрөмжүүд",
    "Холбоо барих",
  ];

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);
  const selectMenu = (index) => {
    setSelectedMenuIndex(index);
    // Open the modal for mobile view
    if (window.innerWidth < 768) {
      setShowModal(true);
    }
  };

  const renderContent = () => {
    switch (selectedMenuIndex) {
      case 0:
        return <UserInfo />;
      case 1:
        return <ChangePassword />;
      case 2:
        return <ChangeMobile />;
      case 3:
        return <ChangeMail />;
      case 4:
        return <AccessHistoryTable />;
      case 5:
        return <TermsAndContract />;
      case 6:
        return <RegisteredDevices />;
      case 7:
        return <ContactInfo />;
      default:
        return <div className="text-gray-600">Coming soon...</div>;
    }
  };

  return (
    <div className="p-4 md:flex space-y-4 md:space-y-0 md:space-x-8 h-[calc(100%-72px)]">
      {/* Sidebar */}
      <div className="w-full md:w-1/4 bg-gray-100 p-6 rounded-lg shadow-md">
        <h2 className="font-bold text-lg mb-4">Хувийн тохиргоо</h2>
        <ul className="space-y-2">
          {menus.map((menu, index) => (
            <li
              key={index}
              className={`p-2 rounded-lg cursor-pointer ${
                selectedMenuIndex === index
                  ? "bg-primary text-white" // Use primary color for the selected menu
                  : "hover:bg-gray-200"
              }`}
              onClick={() => selectMenu(index)}
            >
              {menu}
            </li>
          ))}
        </ul>
      </div>

      {/* Main content area for desktop */}
      <div className="hidden md:block w-full md:w-3/4 bg-gray-100 rounded-lg shadow-md p-4">
        {renderContent()}
      </div>

      {/* Modal for mobile view */}
      <Modal showModal={showModal} onClose={() => setShowModal(false)}>
        <div className="w-full bg-gray-100 rounded-lg shadow-md p-4">
          {renderContent()}
        </div>
      </Modal>
    </div>
  );
};

export default ProfileSettings;
