import { useEffect } from "react";
import Typography from "../../../../../base-components/Typography";
import { useWindowSize } from "../../../../../hooks/windowSize";
import { getScheduleThunk } from "../../../../../redux/slices/product";
import { dispatch, useSelector } from "../../../../../redux/store";
import Button from "../../../../../base-components/Button";

const SecondStep = ({ step, setStep }) => {
  const { height } = useWindowSize();

  const {
    schedule,
    scheduleLoading,
    product,
    selectedDuration,
    selectedAmount,
  } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(
      getScheduleThunk({
        productId: product?.productInfo.id,
        amountId: selectedAmount?.id,
        durationId: selectedDuration?.id,
      })
    );
  }, []);

  return (
    <div>
      <Typography className="font-medium">
        Хуримтлалд мөнгө хийх хувиар
      </Typography>
      <div
        style={{ height: height - 400 }}
        className="overflow-auto w-full mt-4"
      >
        <table className="w-full">
          <thead className="bg-card-base rounded-[5px] sticky top-0 text-font-third">
            <tr>
              <th className="py-2.5 px-2">
                <Typography color="text-font" className="font-normal">
                  Огноо
                </Typography>
              </th>
              <th className="py-2.5 px-2">
                <Typography color="text-font" className="font-normal">
                  Орлого
                </Typography>
              </th>
              <th className="py-2.5 px-2">
                <Typography color="text-font" className="font-normal">
                  Бодогдох хүү
                </Typography>
              </th>
            </tr>
          </thead>
          <tbody>
            {scheduleLoading
              ? "Уншиж байна"
              : schedule?.length > 0 &&
                schedule.map((one) => (
                  <tr key={one.id}>
                    <td className="py-2.5 px-2">
                      <Typography className="text-center">
                        {one?.date}
                      </Typography>
                    </td>
                    <td className="py-2.5 px-2">
                      <Typography className="text-center">
                        {one?.income ?? "0"}₮
                      </Typography>
                    </td>
                    <td className="py-2.5 px-2">
                      <Typography className="text-center">
                        {one?.interest}%
                      </Typography>
                    </td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      <Button
        size="sm"
        className="w-full mt-8"
        onClick={() => {
          //   debugger;
          setStep(step + 1);
        }}
      >
        Үргэлжлүүлэх
      </Button>
    </div>
  );
};

export default SecondStep;
