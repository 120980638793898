import { forwardRef } from "react";
import { twMerge } from "tailwind-merge";

const Card = forwardRef(({ className, size = "sm", ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={twMerge([
        "bg-gray-100 rounded-lg p-4 w-full h-full",
        size === "sm" && "rounded-lg",
        size === "md" && "rounded-xl",
        size === "lg" && "rounded-2xl",
        className,
      ])}
      {...props}
    >
      {props.children}
    </div>
  );
});

export default Card;
