import { StateMachineProvider } from "little-state-machine";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./i18n";
import Container from "./layouts/container";
import Layout from "./layouts/layout";
import Dashboard from "./pages/dashboard";
import Register from "./pages/register";
import UserLogin from "./pages/login";
import RecoverPassword from "./pages/recover-pass";
import Auth from "./layouts/auth";
import ComingSoon from "./pages/coming-soon";
import RemindDevice from "./pages/login/remind-device";
import DeviceVerify from "./pages/login/remind-device/otpVerifyDevice";

import "swiper/swiper-bundle.css";
import ProfileSettings from "./pages/profile";
import MySavings from "./pages/dashboard/savings/details";
import HomePage from "./pages/dashboard/home";
import Product from "./pages/dashboard/products/ProductDetail";
import CreateSavings from "./pages/dashboard/products/create-savings";
import Savings from "./pages/savings";
import ProductDetail from "./pages/product-detail";
import DanResponse from "./pages/profile/dan_response";

function App() {
  return (
    <StateMachineProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Container />}>
            <Route path="/" element={<Layout />}>
              <Route path="home" element={<Dashboard />}>
                <Route path="" element={<HomePage />} />
                <Route path="my-savings" element={<MySavings />} />
                <Route path="product-detail" element={<Product />} />
                <Route path="create-savings" element={<CreateSavings />} />
              </Route>
              <Route path="coming-soon" element={<ComingSoon />} />
              <Route path="user" element={<ProfileSettings />} />
              <Route path="account-management" element={"Coming soon"} />
              <Route path="savings" element={<Savings />} />
              <Route path="dan-response" element={<DanResponse />} />
              <Route path="product-detail/:id" element={<ProductDetail />} />
            </Route>
            <Route path="/" element={<Auth />}>
              <Route path="register" element={<Register />}>
                <Route path=":form" element={<Register />} />
              </Route>
              <Route path="login" element={<UserLogin />}>
                <Route path=":product_id" element={<UserLogin />} />
              </Route>
              <Route path="recover-password" element={<RecoverPassword />} />
              <Route path="not-verify-device" element={<RemindDevice />} />
              <Route path="verify-device" element={<DeviceVerify />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </StateMachineProvider>
  );
}

export default App;
