import { createSlice } from "@reduxjs/toolkit";
// utils
import { dispatch } from "../store";

//services
import ProfileService from "../../services/ProfileService";
import DanService from "../../services/DDanService";
// ----------------------------------------------------------------------

const initialState = {
  accessDeviceLoading: false,
  accessDevices: [],
  error: null,
  success: false,
  registerDeviceLoading: false,
  registerDevices: [],
  danLoading: true,
  danSuccess: false,
  user: null,
  registerAddLoading: false,
  registerAddSuccess: false,
  danReponse: null,
  danCheckLoading: false,
  danConnected: false,
  danCheckSuccess: false,

  changePasswordLoading: false,
  changePasswordError: null,
  changePasswordSuccess: false,

  //total
  totalLoading: false,
  totalError: null,
  total: null,

  //news
  news: []
};

const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    startAccessDeviceLoading(state) {
      state.accessDeviceLoading = true;
    },
    stopAccessDeviceLoading(state) {
      state.accessDeviceLoading = false;
    },
    startDanLoading(state) {
      state.danLoading = true;
    },
    stopDanLoading(state) {
      state.danLoading = false;
    },
    startRegisterDeviceLoading(state) {
      state.registerDeviceLoading = true;
    },
    stopRegisterDeviceLoading(state) {
      state.registerDeviceLoading = false;
    },
    hasError(state, action) {
      state.accessDeviceLoading = false;
      state.registerDeviceLoading = false;
      state.error = action.payload;
    },
    danError(state, action) {
      state.danLoading = false;
      state.danSuccess = true;
      state.error = action.payload;
    },

    accessDeviceSuccess(state, action) {
      state.accessDeviceLoading = false;
      state.accessDevices = action.payload;
    },
    registerDeviceSuccess(state, action) {
      state.registerDeviceLoading = false;
      state.registerDevices = action.payload;
    },
    initialStateProfile(state) {
      state.accessDeviceLoading = false;
      state.error = null;
      state.success = false;
    },
    hideError(state) {
      state.error = null;
    },

    // stopDanLoading(state) {
    //     state.danLoading = false;
    // },
    // startDanLoading(state) {
    //     state.danLoading = true;
    // },
    danSuccess(state) {
      state.danLoading = false;
      state.danSuccess = true;
    },
    changeDanSuccess(state) {
      state.danLoading = false;
      state.danSuccess = false;
    },
    changeDanCheckSuccess(state) {
      state.danCheckLoading = false;
      state.danCheckSuccess = false;
    },
    userSuccess(state, action) {
      state.user = action.payload;
    },
    addRegisterLoading(state) {
      state.registerAddLoading = true;
    },
    addRegisterError(state, action) {
      state.registerAddLoading = false;
      state.error = action.payload;
    },
    addRegisterSuccess(state, action) {
      state.registerAddLoading = false;
      state.registerAddSuccess = true;
      state.danReponse = action.payload;
    },

    changeRegisterAddSuccess(state) {
      state.registerAddLoading = false;
      state.registerAddSuccess = false;
    },

    danCheckError(state, action) {
      state.danCheckLoading = false;
      state.error = action.payload;
    },
    danCheckSuccess(state, action) {
      state.danCheckLoading = false;
      state.danCheckSuccess = true;
      state.danConnected = action.payload;
    },



    startChangePasswordLoading(state) {
      state.changePasswordLoading = true;
    },
    stopChangePasswordLoading(state) {
      state.changePasswordLoading = false;
    },
    changedPasswordSuccess(state, action) {
      state.changePasswordLoading = false;
      state.changePasswordError = null;
      state.changePasswordSuccess = false;

    },
    passwordSuccess(state) {
      state.changePasswordLoading = false;
      state.changePasswordError = null;
      state.changePasswordSuccess = true;
    },
    passwordError(state, action) {
      state.changePasswordLoading = false;
      state.changePasswordError = action.payload;
      state.changePasswordSuccess = false;
    },

    startTotalLoading(state) {
      state.totalLoading = true;
    },
    stopTotalLoading(state) {
      state.totalLoading = false;
    },
    totalSuccess(state, action) {
      state.total = action.payload;
      state.totalError = null;

    },

    totalError(state, action) {
      state.totalLoading = false;
      state.totalError = action.payload;
    },

    newsSuccess(state, action) {
      state.news = action.payload;
    },


  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  startAccessDeviceLoading,
  stopAccessDeviceLoading,
  hideError,
  initialStateProfile,
  changeDanSuccess,
  changeRegisterAddSuccess,
  changeDanCheckSuccess,
  changedPasswordSuccess
} = slice.actions;

// // ----------------------------------------------------------------------

export function accessHistoryThunk() {
  return async () => {
    dispatch(slice.actions.startAccessDeviceLoading());
    try {
      const response = await ProfileService.accessHistory();
      dispatch(slice.actions.accessDeviceSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.hasError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}

export function registeredDevicesThunk() {
  return async () => {
    dispatch(slice.actions.startRegisterDeviceLoading());
    try {
      const response = await ProfileService.registeredDevices();
      dispatch(slice.actions.registerDeviceSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.hasError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}
function getQueryParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  console.log("Code:", code);
  console.log("State:", state);
  return `code=${code}&state=${state}`;
}

export function danConnectThunk() {
  return async () => {
    console.log("getQueryParams();", getQueryParams());
    dispatch(slice.actions.startDanLoading());
    try {
      await DanService.connectDan(getQueryParams());
      dispatch(slice.actions.danSuccess());
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.danError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}

export function getUserInfo() {
  return async () => {
    // dispatch(slice.actions.startDanLoading());
    try {
      var response = await ProfileService.infoDetail();
      console.log("responseresponse", response);
      dispatch(slice.actions.userSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      // dispatch(slice.actions.danError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}
export function danRegisterAdd(body) {
  return async () => {
    dispatch(slice.actions.addRegisterLoading(body));
    try {
      const response = await DanService.checkRegisterDan(body);
      dispatch(slice.actions.addRegisterSuccess(response));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.addRegisterError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}
export function danCheckThunk() {
  return async () => {
    dispatch(slice.actions.startDanLoading());
    try {
      const response = await DanService.danCheck();
      dispatch(slice.actions.danCheckSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.danCheckError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}
export function changePasswordThunk(body) {
  return async () => {
    dispatch(slice.actions.startChangePasswordLoading());
    try {
      const response = await ProfileService.changePassword(body);
      dispatch(slice.actions.passwordSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.passwordError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}


export function totalThunk() {
  return async () => {
    dispatch(slice.actions.startTotalLoading());
    try {
      const response = await ProfileService.total();
      dispatch(slice.actions.totalSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.totalError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };


}

export function newsThunk() {
  return async () => {
    console.log("getQueryParams();", getQueryParams());
    // dispatch(slice.actions.startDanLoading());
    try {
      const res = await ProfileService.news();
      dispatch(slice.actions.newsSuccess(res.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.danError(error));
      // dispatch(slice.actions.checkAccessSuccess(phoneNumber));
    }
  };
}