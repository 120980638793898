import { createSlice } from "@reduxjs/toolkit";
// utils
import { dispatch } from "../store";

//services
import ProductService from "../../services/ProductService";
import OrderService from "../../services/SavingsService";
import dayjs from "dayjs";
// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  orders: [],
  error: null,
  success: false,


  //productDetail
  selectedOrder: null,
  detailLoading: true,
  detailSuccess: false,
  detailError: null,
  selectOrderId: null,

  //money add
  moneyAddModal: false,

  //scheduled
  scheduledLoading: false,
  unpaidSchedule: [],
  schedule: [],
  scheduledError: null,
  scheduledSuccess: false,

  //modal steps
  step: 1,

  //payment
  paymentLoading: false,
  paymentSuccess: false,
  paymentError: null,
  paymentResponse: null,
  selectSchedules: [],

  //check invoice
  invoideLoading: false,
  invoiceSuccess: false,
  invoiceError: null,
  startDate: dayjs().month(dayjs().month() - 1),
  endDate: dayjs(),


  // const [startDate, setStartDate] = useState(dayjs().month(dayjs().month() - 1));
  // const [endDate, setEndDate] = useState(dayjs());


};

const slice = createSlice({
  name: "savings",
  initialState,
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },

    getOrderDataSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.orders = action.payload;
      if (action.payload && action.payload.length > 0) {
        state.selectOrderId = action.payload[0].id;
      }

    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },


    //product detail
    startDetailLoading(state) {
      state.detailLoading = true;
    },

    stopDetailLoading(state) {
      state.detailLoading = false;
    },

    detailError(state, action) {
      state.detailLoading = false;
      state.detailError = action.payload;
    },
    detailSuccess(state, action) {
      state.detailLoading = false;
      state.selectedOrder = action.payload;

    },
    setSelectOrderId(state, action) {
      state.selectOrderId = action.payload;
      state.startDate = dayjs().month(dayjs().month() - 1);
      state.endDate = dayjs();
    },

    //scheduled

    startScheduledLoading(state) {
      state.scheduledLoading = true;
    },
    scheduledSuccess(state, action) {
      state.scheduledLoading = false;
      if (action.payload.unpaidSchedule && action.payload.unpaidSchedule.length > 0) {
        state.unpaidSchedule = action.payload.unpaidSchedule;
      }
      if (action.payload.schedule && action.payload.schedule.length > 0) {
        state.schedule = action.payload.schedule;
      }
      state.step = 1;
      state.scheduledSuccess = true;
      state.scheduledError = null;
      state.moneyAddModal = true;
    },
    //scheduled error
    scheduledError(state, action) {
      state.scheduledLoading = false;
      state.unpaidSchedule = [];
      state.schedule = [];
      state.scheduledError = action.payload;
    },

    closeMoneyModal(state) {
      state.moneyAddModal = false;
    },
    setMoneyStep(state, action) {
      state.step = action.payload;
    },



    //payment 
    paymentSuccess(state, action) {
      state.paymentResponse = action.payload;
      state.paymentLoading = false;
      state.paymentSuccess = true;
      state.step = state.step + 1;

      state.invoiceError = null;
    },

    startPaymentLoading(state) {
      state.invoiceError = null;
      state.paymentLoading = true;
    },
    paymentError(state, action) {
      state.paymentError = action.payload;
      state.paymentLoading = false;
    },
    changeSelectedSchedule(state, action) {
      const index = state.selectSchedules.findIndex(e => e.id == action.payload.id);
      console.log("iishee yu irjiin", index, action.payload);
      if (index > -1) {
        state.selectSchedules.splice(index, 1);
        console.log("iishee yu irjiin", index, state.selectSchedules);
      } else {
        if (action.payload) {
          console.log("iishee yu irjiin", index, state.selectSchedules);
          state.selectSchedules.push(action.payload)
        }
      }
    },

    //payment 
    invoiceSuccess(state) {
      state.invoiceSuccess = true;
      state.invoideLoading = false;
      state.moneyAddModal = false;
      state.step = 1;
    },
    invoiceError(state, action) {
      state.invoiceSuccess = false;
      state.invoiceError = action.payload;
    },
    startInvoiceLoading(state) {
      state.invoiceError = null;
      state.invoideLoading = true;
    },
    changeInvoiceSuccess(state) {
      state.invoiceError = null;
      state.invoiceSuccess = false;
    },

    changeStartDate(state, action) {
      state.startDate = action.payload
    },
    changeEndDate(state, action) {
      state.endDate = action.payload

    }



  },
});





// Reducer
export default slice.reducer;

// Actions
export const { changeStartDate, changeEndDate, startLoading, stopLoading, setSelectOrderId, closeMoneyModal, setMoneyStep, changeSelectedSchedule, changeInvoiceSuccess } = slice.actions;

// // ----------------------------------------------------------------------

export function getOrderListThunk() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await OrderService.getOrderList();
      console.log("getOrderList", response);
      dispatch(slice.actions.getOrderDataSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.hasError(error));
      // dispatch(slice.actions.checkRegisterSuccess(phoneNumber));
    }
  };
}


export function getOrderDetailThunk(id, startDate, endDate) {
  return async () => {
    console.log("getOrderDetailThunk startDate", startDate);
    console.log("getOrderDetailThunk endDate", endDate);
    dispatch(slice.actions.startDetailLoading());
    try {
      const response = await OrderService.getOrderDetail(id, startDate, endDate);
      console.log("getOrderList", response);
      dispatch(slice.actions.detailSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.detailError(error));
      // dispatch(slice.actions.checkRegisterSuccess(phoneNumber));
    }
  };
}
export function getOrderScheduledThunk(id) {
  return async () => {
    dispatch(slice.actions.startScheduledLoading());
    try {
      const response = await OrderService.getScheduled(id);
      console.log("getOrderList", response);
      dispatch(slice.actions.scheduledSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu

      // dispatch(slice.actions.scheduledSuccess({}));
      dispatch(slice.actions.scheduledError(error));
      // dispatch(slice.actions.checkRegisterSuccess(phoneNumber));
    }
  };
}
export function paymentThunk(data) {
  return async () => {
    dispatch(slice.actions.startPaymentLoading());
    try {
      const response = await OrderService.payment(data);
      console.log("paymentSuccess", response);
      dispatch(slice.actions.paymentSuccess(response.responseData));
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.paymentError(error));
      // dispatch(slice.actions.checkRegisterSuccess(phoneNumber));
    }
  };
}
export function checkInvoiceThunk(invoiceId) {
  return async () => {
    dispatch(slice.actions.startInvoiceLoading());
    try {
      const response = await OrderService.checkInvoice(invoiceId);
      if (response.responseData == 0) {
        dispatch(slice.actions.invoiceError(response.responseMsg));
      } else {
        console.log("paymentSuccess", response);
        dispatch(slice.actions.invoiceSuccess());

      }
    } catch (error) {
      //eniig taviishuu
      dispatch(slice.actions.invoiceError(error));
      // dispatch(slice.actions.checkRegisterSuccess(phoneNumber));
    }
  };
}