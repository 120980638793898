import { dispatch, useSelector } from "../../../redux/store";
import { TbChevronLeft } from "react-icons/tb";
import Button from "../../../base-components/Button";
import { useNavigate } from "react-router-dom";
import Typography from "../../../base-components/Typography";
import { twMerge } from "tailwind-merge";
import HTMLReactParser from "html-react-parser";
import { useEffect, useState } from "react";
import AlertModal from "../../../base-components/AlertModal";
import {
  changeDanCheckSuccess,
  danCheckThunk,
} from "../../../redux/slices/profile";

const ProductDetail = () => {
  const navigate = useNavigate();
  const { product } = useSelector((state) => state.product);
  const { danCheckSuccess, danConnected } = useSelector(
    (state) => state.profile
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [htmlContent, setHtmlContent] = useState("<h1>Hello, World!</h1>");

  useEffect(() => {
    console.log("iishee orjiinuu", danCheckSuccess);
    if (danCheckSuccess) {
      if (danConnected.isEmail) {
        if (danConnected.isDan) {
          //   setStep(5);
        } else {
          //   setStep(4);
        }
        //
      } else {
        // setStep(2);
      }
      dispatch(changeDanCheckSuccess());
    }
  }, [danCheckSuccess, danConnected]);

  return (
    <div className="p-4">
      <Button
        variant="text"
        icon={<TbChevronLeft size={26} />}
        onClick={() => navigate("/home")}
      >
        Буцах
      </Button>
      <Typography variant="Headline" size="sm" className="font-medium m-4">
        Санал болгож буй бүтээгдэхүүн
      </Typography>
      <div className="flex justify-center md:px-10 xs:px-6 px-4">
        <div className="shadow-card rounded-base p-4 grid gap-4 md:w-[550px] w-full">
          <Typography variant="Title" className="font-medium">
            {product?.productInfo?.name}
          </Typography>
          <div
            className={twMerge([
              "w-full h-72 rounded-base",
              !product?.productInfo?.imagePath && "bg-[#D9D9D9]",
            ])}
          >
            {product?.productInfo?.imagePath && (
              <img
                src={
                  "https://geregefund.mn" +
                  product?.productInfo?.imagePath.replace(
                    "https://admin.geregefund.mn",
                    ""
                  )
                }
                alt={product?.productInfo?.name}
                className="w-full h-full object-cover rounded-base"
              />
            )}
          </div>
          <Typography variant="Title" className="font-medium">
            {HTMLReactParser(product?.productInfo?.description ?? htmlContent)}
          </Typography>
          <Button
            size="sm"
            className="mt-6"
            onClick={() => {
              setOpenDialog(true);
              //   dispatch(danCheckThunk());
            }}
          >
            Хуримтлал нээх
          </Button>
        </div>
      </div>
      <AlertModal
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        type="info"
        message="Баталгаажуулахын тулд танилт нэвтрэлтийн дан систем рүү шилжүүллээ"
        footer={
          <div className="grid xs:flex items-center sm:gap-10 gap-4">
            <Button
              size="sm"
              variant="outlinePrimary"
              className="w-40"
              onClick={() => setOpenDialog(false)}
            >
              Цуцлах
            </Button>
            <Button
              size="sm"
              className="w-40"
              onClick={() => {
                navigate("/home/create-savings");
                // dispatch(danCheckThunk());
                // setStep(step + 1);
              }}
            >
              Тийм
            </Button>
          </div>
        }
        placement="top"
        size="xl"
      />
    </div>
  );
};

export default ProductDetail;
