import { React, forwardRef, useImperativeHandle, useState } from "react";
import { LuX } from "react-icons/lu";
import { twMerge } from "tailwind-merge";
import { Popover, PopoverProps } from "@mui/material";
import Typography from "../Typography";

const Dropdown = forwardRef(
  (
    {
      target,
      children,
      onClick,
      disabled,
      targetClassName,
      title,
      onClose,
      className,
      marginTop = 4,
      dropdownClassName,
      horizontal = "center",
      isOpen = true,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = useState();
    const open = Boolean(anchorEl);
    const id = open ? "mui-popover" : undefined;

    useImperativeHandle(
      ref,
      () => {
        return {
          close() {
            handleClose();
          },
        };
      },
      []
    );

    const handleClick = (e) => {
      if (isOpen) {
        setAnchorEl(e.currentTarget);
      }
      onClick?.(e);
    };
    const handleClose = () => {
      setAnchorEl(undefined);
      onClose?.();
    };

    return (
      <>
        <button
          type="button"
          onClick={handleClick}
          disabled={disabled}
          className={twMerge([
            "outline-none disabled:opacity-50 disabled:cursor-not-allowed",
            targetClassName,
          ])}
        >
          {target}
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: horizontal,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: horizontal,
          }}
          slotProps={{
            paper: {
              style: {
                background: "transparent",
                boxShadow: "none",
                color: "white",
                borderRadius: 0,
                marginTop: marginTop,
              },
            },
          }}
          wrapperClassName={twMerge([
            "p-0 bg-primary-soft min-w-56",
            className,
          ])}
        >
          {title && (
            <div className="flex items-center justify-between border-b border-stroke px-4 py-2">
              <Typography>{title}</Typography>
              <button onClick={handleClose} className="outline-none">
                <LuX />
              </button>
            </div>
          )}
          <div className={twMerge(["px-4 py-2", dropdownClassName])}>
            {children}
          </div>
        </Popover>
      </>
    );
  }
);

Dropdown.displayName = "Dropdown";

export default Dropdown;
