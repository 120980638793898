import {
  Select,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@headlessui/react";
import Button from "../../../../base-components/Button";
import Typography from "../../../../base-components/Typography";
import Input from "../../../../base-components/Input";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { dispatch, useSelector } from "../../../../redux/store";
import {
  changeRegisterAddSuccess,
  danRegisterAdd,
} from "../../../../redux/slices/profile";
import RDInput from "../../../../components/RDInput";

const RegisterForm = ({ step, setStep }) => {
  // state.registerAddLoading = false;
  //         state.registerAddSuccess = false;
  const { registerAddLoading, registerAddSuccess, danReponse } = useSelector(
    (state) => state.profile
  );
  const { product } = useSelector((state) => state.product);
  const { danConnected } = useSelector((state) => state.profile);

  const [regNum, setRegNum] = useState();
  const [isCompany, setIsCompany] = useState(0);

  const onContinue = () => {
    console.log("regNumregNum", regNum);
    // setStep(step + 1);
    dispatch(
      danRegisterAdd({
        register: regNum,
        isCompany: isCompany,
      })
    );
  };
  useEffect(() => {
    console.log("danConnected", danConnected);
    if (danConnected.isDan == "1") {
      setStep(1);
    }
  }, []);
  useEffect(() => {
    if (registerAddSuccess) {
      const url = `${danReponse.urlAuthorize}?response_type=code&client_id=${danReponse.clientId}&redirect_uri=${danReponse.redirectUri}&state=${product?.productInfo.id}&scope=${danReponse.wsdls}`;

      window.location.assign(url);
      // setStep(step + 1);
      console.log("danReponse", danReponse, url);

      dispatch(changeRegisterAddSuccess());
    }
  }, [registerAddSuccess]);

  return (
    <>
      <div className="grid gap-5 mt-5">
        {/* Ene deer neg register talbar utga oruulah ym baigaa
            Deeree baiguulaga huvi hun gesen songolttoi */}
        <TabGroup>
          <TabList>
            <Tab
              onClick={() => {
                setIsCompany(0);
              }}
              className="rounded-full py-1 px-3 text-sm/6 font-semibold text-primary focus:outline-none data-[selected]:bg-primary data-[selected]:text-white data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-primary/90 data-[focus]:outline-1 data-[focus]:outline-white"
            >
              Хувь хүн
            </Tab>
            <Tab
              onClick={() => {
                setIsCompany(1);
              }}
              className="rounded-full py-1 px-3 text-sm/6 font-semibold text-primary focus:outline-none data-[selected]:bg-primary data-[selected]:text-white data-[hover]:bg-white/5 data-[selected]:data-[hover]:bg-primary/90 data-[focus]:outline-1 data-[focus]:outline-white"
            >
              Байгууллага
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel className="rounded-xl bg-white my-4 p-4">
              <RDInput
                onChange={(value) => {
                  console.log(value, "RD value");
                  setRegNum(value);
                }}
              />
            </TabPanel>
          </TabPanels>
        </TabGroup>
        <div className="flex justify-center">
          <Button disabled={registerAddLoading} onClick={onContinue}>
            Үргэлжлүүлэх
          </Button>
        </div>
      </div>
    </>
  );
};

export default RegisterForm;
